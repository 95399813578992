import React, {useState, useMemo, useCallback} from 'react'
import PropTypes from 'prop-types'
import {withTranslation} from 'react-i18next'
import DOMPurify from 'dompurify'

import {useGetUserById} from 'api/hooks/useGetUserById'
import {useGetPostsByUserId} from 'api/hooks/useGetPostsByUserId'
import {useDeletePostById} from 'api/hooks/useDeletePostById'
import {useBlockUnblockUser} from 'api/hooks/useBlockUnblockUser'
import {useDeleteUserById} from 'api/hooks/useDeleteUserById'
import withRouter from 'components/Common/withRouter'
import ModalSimple from 'components/Common/ModalSimple'
import DeleteModal from 'components/Common/DeleteModal'
import ConfirmModal from 'components/ConfirmModal'
import toastr from 'components/toastr/index'
import {downloadPost} from 'helpers/downloadPost'
import capitalizeFirstLetter from 'helpers/capitalizeFirstLetter'

import UserDetailPageView from './view'
import ProfileDetails from './ProfileDetails'
import ProfilePosts from './ProfilePosts'
import {getBlockText} from '../../helpers/getBlockText'

const UserDetailPage = ({t, router}) => {
  document.title = 'User details'
  const {userId} = router.params

  const [postsPage, setPostsPage] = useState(1)
  const [postViewInfo, setPostViewInfo] = useState(null)
  const [postDeleteInfo, setPostDeleteInfo] = useState(null)
  const [userBlockModal, setUserBlockModal] = useState(false)
  const [userDeleteModal, setUserDeleteModal] = useState(false)
  const [filters, setFilters] = useState({})

  const sanitizedHTML = DOMPurify.sanitize(postViewInfo?.description)

  const {user, error, loading, update: updateUser} = useGetUserById(userId)
  const {
    posts,
    loading: postsLoading,
    fetchPosts,
    paginationData,
  } = useGetPostsByUserId(userId, {page: postsPage, filters})
  const {deleteFetch, loading: deleteLoading} = useDeletePostById()
  const {blockUnblockUser, loading: blockUserLoading} = useBlockUnblockUser(userId)
  const {deleteFetch: userDeleteFetch, loading: userDeleteLoading} = useDeleteUserById()

  const blockText = useMemo(() => getBlockText(user?.status), [user])

  const onBlockUser = useCallback(() => {
    blockUnblockUser(userId).then(() => {
      setUserBlockModal(false)
      updateUser()
      toastr.success(t(`User successfully ${blockText}ed`))
    })
  }, [blockText, setUserBlockModal, updateUser])

  const onDeleteUser = useCallback(() => {
    userDeleteFetch(userId).then(() => {
      setUserDeleteModal(false)
      router.navigate('/user-management')
      toastr.success(t(`User successfully deleted`))
    })
  }, [setUserDeleteModal])

  const onPostView = useCallback(
    (postId) => {
      const post = posts.find(({id}) => id === postId)
      post && setPostViewInfo(post)
    },
    [posts],
  )

  const onClosePostView = useCallback(() => setPostViewInfo(null), [])

  const onPostDownload = useCallback(
    (postId) => {
      const post = posts.find(({id}) => id === postId)
      post && downloadPost(post)
    },
    [posts],
  )

  const onDeletePostRequest = useCallback(
    (postId) => {
      const post = posts.find(({id}) => id === postId)
      post && setPostDeleteInfo(post)
    },
    [posts],
  )

  const onDeletePostCancel = useCallback(() => setPostDeleteInfo(null), [])

  const onDeletePostConfirm = useCallback(() => {
    deleteFetch(postDeleteInfo.id).then(() => {
      fetchPosts()
      setPostDeleteInfo(null)
      toastr.success(t('Post was deleted'))
    })
  }, [postDeleteInfo])

  const onBlockUserRequest = useCallback(() => setUserBlockModal(true), [setUserBlockModal])
  const onBlockUserCancel = useCallback(() => setUserBlockModal(false), [setUserBlockModal])
  const onDeleteUserRequest = useCallback(() => setUserDeleteModal(true), [setUserBlockModal])
  const onDeleteUserCancel = useCallback(() => setUserDeleteModal(false), [setUserBlockModal])

  const onChangeFilters = useCallback((values) => {
    setFilters(values)
    setPostsPage(1)
  }, [])

  const profileDetails = useMemo(
    () => (
      <ProfileDetails
        loading={loading}
        user={user}
        onBlockClick={onBlockUserRequest}
        onDeleteClick={onDeleteUserRequest}
        blockButtonText={capitalizeFirstLetter(blockText)}
      />
    ),
    [loading, user, onBlockUser, onDeleteUser],
  )

  const profilePosts = useMemo(
    () => (
      <ProfilePosts
        loading={postsLoading}
        posts={posts}
        setActivePage={setPostsPage}
        onPostView={onPostView}
        onPostDownload={onPostDownload}
        onPostDeleteClick={onDeletePostRequest}
        filters={filters}
        onChangeFilters={onChangeFilters}
        pagination={paginationData}
      />
    ),
    [postsLoading, posts, setPostsPage, onPostView, onPostDownload, onDeletePostRequest, paginationData],
  )

  return (
    <>
      <UserDetailPageView errorMsg={error} profileDetails={profileDetails} profilePosts={profilePosts} />
      <ModalSimple
        isOpen={Boolean(postViewInfo)}
        onClose={onClosePostView}
        title={postViewInfo?.title}
        headerClassNames="text-break"
        bodyClassNames="text-break postDetails-modalBody"
      >
        <div dangerouslySetInnerHTML={{__html: sanitizedHTML}} />
      </ModalSimple>
      <DeleteModal
        isOpen={Boolean(postDeleteInfo)}
        onClose={onDeletePostCancel}
        onDelete={onDeletePostConfirm}
        title={t('Delete post')}
        text={t('Are you sure you want to delete post? This post will be deleted for all users with access.')}
        isLoading={deleteLoading}
      />
      <ConfirmModal
        title={t(`${capitalizeFirstLetter(blockText)} user`)}
        isOpen={userBlockModal}
        onClickSubmit={onBlockUser}
        onClickCancel={onBlockUserCancel}
        submitButtonName={t(capitalizeFirstLetter(blockText))}
        submitButtonClassNames="btn btn-primary"
        isLoading={blockUserLoading}
      >
        {t(`Are you sure you want to ${blockText} the User?`)}
      </ConfirmModal>
      <ConfirmModal
        title={t(`Delete user`)}
        isOpen={userDeleteModal}
        onClickSubmit={onDeleteUser}
        onClickCancel={onDeleteUserCancel}
        submitButtonName={t(`Delete`)}
        submitButtonClassNames="btn btn-danger"
        isLoading={userDeleteLoading}
      >
        {t(`Are you sure you want to delete the User?`)}
      </ConfirmModal>
    </>
  )
}

export default withTranslation()(withRouter(UserDetailPage))

UserDetailPage.propTypes = {
  router: PropTypes.object,
  t: PropTypes.func,
}
