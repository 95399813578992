import React from 'react'
import PropTypes from 'prop-types'
import {Modal, ModalHeader, ModalBody} from 'reactstrap'

const ModalSimple = ({isOpen, onClose, title, text, headerClassNames = '', bodyClassNames = '', children}) => {
  return (
    <Modal isOpen={isOpen} centered>
      <ModalHeader toggle={onClose} className={headerClassNames}>
        {title}
      </ModalHeader>
      <ModalBody className={'modalSimple-body ' + bodyClassNames}>
        {!!text && text}
        {!!children && children}
      </ModalBody>
    </Modal>
  )
}

ModalSimple.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  text: PropTypes.string,
  headerClassNames: PropTypes.string,
  bodyClassNames: PropTypes.string,
  children: PropTypes.node,
}

export default ModalSimple
