import React, {useEffect, useRef} from 'react'

export const useOutsideClick = (callback, excludeIds = []) => {
  const ref = useRef()

  useEffect(() => {
    const handleClick = (event) => {
      const isExcluded = excludeIds.some((id) => {
        const element = document.getElementById(id)
        return element?.contains(event.target)
      })

      if (ref.current && !ref.current.contains(event.target) && !isExcluded) {
        callback()
      }
    }

    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [callback, excludeIds])

  return ref
}
