import React from 'react'
import PropTypes from 'prop-types'

const TableRow = ({className, onClick, children}) => {
  return (
    <tr className={'cursor-pointer ' + className} onClick={() => onClick?.()}>
      {children}
    </tr>
  )
}

TableRow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
}

export default TableRow
