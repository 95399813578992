import React, {useState} from 'react'
import PropTypes from 'prop-types'
import NoPhoto from 'assets/images/ic-user.svg'
import {Spinner} from 'reactstrap'
import classNames from 'classnames'

const ImageSquare = ({src, onError, withLoader, alt}) => {
  const [isLoading, setIsLoading] = useState(true)
  return (
    <div className="imageSquare-root">
      <div className="imageSquare-box">
        {src && (
          <div className="d-flex justify-content-center align-items-center h-100">
            <img
              onLoad={() => {
                setIsLoading(false)
              }}
              className={classNames('imageSquare-image', {'d-none': isLoading && withLoader})}
              src={src}
              alt={alt}
            />

            {withLoader && <Spinner color="info" className={classNames('m-auto w-10 ', {'d-none': !isLoading})} />}
          </div>
        )}
        {!src && (
          <div className="d-flex align-items-center justify-content-center imageSquare-image bg-light">
            <img src={NoPhoto} alt="No content" onError={onError} />
          </div>
        )}
      </div>
    </div>
  )
}

ImageSquare.propTypes = {
  src: PropTypes.string,
  onError: PropTypes.func,
  alt: PropTypes.string,
  withLoader: PropTypes.bool,
}

export default ImageSquare
