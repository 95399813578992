import React, {useCallback, useState, useMemo} from 'react'
import {withTranslation} from 'react-i18next'
import {Container} from 'reactstrap'
import {useDeleteCommentById} from 'api/hooks/useDeleteCommentById'
import {useReportsByCommentId} from 'api/hooks/useReportsByCommentId'
import SEO from 'components/Common/SEO'
import withRouter from 'components/Common/withRouter'
import Breadcrumbs from 'components/Common/Breadcrumb'
import toastr from 'components/toastr/index'
import {POST_REPORTS_LIMIT} from 'constants/reportManagement'
import ConfirmModal from 'components/ConfirmModal'
import DeleteModal from 'components/Common/DeleteModal'
import capitalizeFirstLetter from 'helpers/capitalizeFirstLetter'
import {BLOCK, UNBLOCK} from 'constants/userManagement'
import {useBlockUnblockUser} from 'api/hooks/useBlockUnblockUser'
import {useGetCommentById} from 'api/hooks/useGetCommentById'

import CommentInfo from './components/CommentInfo'
import ReportsTable from './components/ReportsTable'
import {POST_REPORTEDS_TABLE_DATA_STRUCTURE, POST_REPORTEDS_TABLE_HEADER} from './tableComponents/reportTablesStructure'
import PropTypes from 'prop-types'

const CommentReports = ({t, router}) => {
  const {commentId} = router.params
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [userBlockModal, setUserBlockModal] = useState(false)
  const {comment, loading, update: updateComment} = useGetCommentById(commentId)
  const {deleteFetch, loading: deleteLoading} = useDeleteCommentById()
  const blockText = useMemo(() => (comment?.user?.status === 'Blocked' ? UNBLOCK : BLOCK), [comment])
  const {blockUnblockUser, loading: blockUserLoading} = useBlockUnblockUser(comment?.user?.id)
  const deleteText = useMemo(() => (comment?.isReply ? 'reply' : 'comment'), [comment])
  const onBlockUserRequest = useCallback(() => setUserBlockModal(true), [setUserBlockModal])
  const onBlockUserCancel = useCallback(() => setUserBlockModal(false), [setUserBlockModal])
  const onDeleteCommentCancel = useCallback(() => setOpenDeleteModal(false), [])
  const onBlockUser = useCallback(() => {
    blockUnblockUser(comment?.user?.id).then(() => {
      setUserBlockModal(false)
      updateComment()
      toastr.success(t(`User successfully ${blockText}ed`))
    })
  }, [blockText, setUserBlockModal, comment, updateComment])

  const onCommentDelete = useCallback(() => setOpenDeleteModal(true), [])
  const onDeleteCommentConfirm = useCallback(() => {
    deleteFetch(commentId).then(() => {
      setOpenDeleteModal(false)
      router.navigate('/report-management')
      toastr.success(t(`${capitalizeFirstLetter(deleteText)} was deleted`))
    })
  }, [])

  return (
    <>
      <SEO title="Reported comment details" />
      <div className="page-content pb-0">
        <Breadcrumbs title={t('Reports')} breadcrumbItem={t(`Reported ${deleteText} details`)} />
        <Container fluid>
          <div className="postReportsPage_container">
            <CommentInfo
              comment={comment}
              loading={loading}
              onCommentDelete={onCommentDelete}
              onBlockUser={onBlockUserRequest}
            />
            <ReportsTable
              commentId={commentId}
              paginationLimit={POST_REPORTS_LIMIT}
              getService={useReportsByCommentId}
              tableHeaderStructure={POST_REPORTEDS_TABLE_HEADER}
              tableDataStructure={POST_REPORTEDS_TABLE_DATA_STRUCTURE}
              noDataText="No Reports found"
              hideLoader={loading}
            />
            <DeleteModal
              isOpen={openDeleteModal}
              onClose={onDeleteCommentCancel}
              onDelete={onDeleteCommentConfirm}
              title={t('Delete')}
              text={t(
                `Are you sure you want to delete ${deleteText}? This ${deleteText} will be deleted for all users.`,
              )}
              isLoading={deleteLoading}
            />
            <ConfirmModal
              title={t(`${capitalizeFirstLetter(blockText)} user`)}
              isOpen={userBlockModal}
              onClickSubmit={onBlockUser}
              onClickCancel={onBlockUserCancel}
              submitButtonName={t(capitalizeFirstLetter(blockText))}
              submitButtonClassNames="btn btn-primary"
              isLoading={blockUserLoading}
            >
              {t(`Are you sure you want to ${blockText} the User?`)}
            </ConfirmModal>
          </div>
        </Container>
      </div>
    </>
  )
}

CommentReports.propTypes = {
  t: PropTypes.func,
  router: PropTypes.shape({
    params: PropTypes.shape({
      commentId: PropTypes.string,
    }),
    navigate: PropTypes.func,
  }),
}

export default withTranslation()(withRouter(CommentReports))
