import React from 'react'

import {icons} from 'constants/svgIcons'
import PropTypes from 'prop-types'

const Icon = ({icon, isActive}) => {
  const Icon = icons[icon]

  return <Icon fill={isActive ? 'white' : 'none'} />
}

Icon.propTypes = {
  icon: PropTypes.oneOf(Object.keys(icons)),
  isActive: PropTypes.bool,
}

export default Icon
