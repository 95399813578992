import React from 'react'
import TableSortIcon from './TableSort'
import PropTypes from 'prop-types'
import {ASC, DESC, NOT_DEFINED} from '../../constants/tableConstants'
import classNames from 'classnames'

const TableHeader = ({columns, clickCellAction}) => {
  return (
    <thead>
      <tr>
        {columns.map((column, index) => {
          return (
            <th key={column.label || index} className={`table-caption ${column?.classname}`}>
              <button
                onClick={() => clickCellAction?.(column)}
                className={classNames(`header-button ${!column?.sortOption ? 'disabled' : 'header-button'}`)}
              >
                {column.label}
                {column.sortOption && column?.sortDirection && <TableSortIcon currentSort={column.sortDirection} />}
              </button>
            </th>
          )
        })}
      </tr>
    </thead>
  )
}
TableHeader.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      classname: PropTypes.string,
      sortOption: PropTypes.bool,
      sortDirection: PropTypes.oneOf([ASC, DESC, NOT_DEFINED]),
    }),
  ),
  clickCellAction: PropTypes.func,
}
export default TableHeader
