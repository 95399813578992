import {useState, useCallback} from 'react'
import debounce from 'lodash.debounce'
import {useGetUsers} from '../api/hooks/useGetUsers'
import {ALLOWED_SORT_DIRECTIONS, TIME_TO_DEBOUNCE} from '../constants/tableConstants'
import isAllowedSort from '../helpers/isAllowedSort'

export const useUsersTable = (initialState) => {
  const [currentOffset, setCurrentOffset] = useState(initialState.offset)
  const [searchString, setSearchString] = useState(initialState.query)
  const [filters, setFilters] = useState(initialState.curFilters)
  const [tableSort, setTableSort] = useState(initialState.sorting)

  const {usersList, pagination, loading} = useGetUsers({
    ...(filters?.Status && {Status: filters.Status}),
    ...(filters?.Subscription && {Subscription: filters.Subscription}),
    ...(filters?.SubscriptionPlan && {SubscriptionPlan: filters.SubscriptionPlan}),
    ...(searchString && {Search: searchString}),
    ...(tableSort?.OrderKey && isAllowedSort(tableSort?.OrderDirection, ALLOWED_SORT_DIRECTIONS) && tableSort),
    Offset: currentOffset,
  })

  const debounceSearch = useCallback(
    debounce((e) => {
      setSearchString(e?.target?.value)
      setCurrentOffset(0)
    }, TIME_TO_DEBOUNCE),
    [setSearchString, setCurrentOffset],
  )

  return {
    currentOffset,
    setCurrentOffset,
    searchString,
    setSearchString,
    filters,
    setFilters,
    tableSort,
    setTableSort,
    usersList,
    pagination,
    loading,
    debounceSearch,
  }
}
