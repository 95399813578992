import React from 'react'
import {Link} from 'react-router-dom'

import logo from 'assets/images/logoYTT.svg'

const AuthLogo = () => {
  return (
    <div className="auth-logo">
      <Link to="/" className="logo-light d-block">
        <div className="avatar-md profile-user-center mb-4">
          <span className="avatar-title rounded-circle bg-light">
            <img src={logo} alt="App logo" className="rounded-circle" height="72" />
          </span>
        </div>
      </Link>
    </div>
  )
}

export default AuthLogo
