import Http from './axios-config'

class SubscriptionService {
  constructor() {
    this.http = new Http()
  }

  async getSubscriptionsByUserId(userId, offset = 0, limit = 12) {
    return await this.http.get(`/admin-subscriptions?userId=${userId}&limit=${limit}&offset=${offset}`)
  }

  async giveFreeSubscription(values) {
    return await this.http.post(`/admin-subscriptions`, values)
  }

  async deleteSubscriptionById(id) {
    return await this.http.delete(`/admin-subscriptions/${id}`)
  }

  async changeFreeSubscription(subscriptionId, values) {
    return await this.http.patch(`/admin-subscriptions/${subscriptionId}`, values)
  }
}

export default new SubscriptionService()
