import React from 'react'
import {Row, Col, Card, CardBody, Container, Input, Label, Form} from 'reactstrap'
import {useDispatch} from 'react-redux'
import {Link} from 'react-router-dom'
import {ErrorMessage, Formik} from 'formik'

import withRouter from 'components/Common/withRouter'
import FormError from 'components/Common/FormError'
import toastr from 'components/toastr/index'
import SEO from 'components/Common/SEO'
import {forgotPwValidationSchema} from 'constants/validationSchemes'
import {userForgetPassword} from 'store/actions'

import AuthHeader from './components/AuthHeader'
import AuthLogo from './components/AuthLogo'
import PropTypes from 'prop-types'

const ForgetPasswordPage = (props) => {
  const dispatch = useDispatch()

  const handleSubmit = (values) => {
    dispatch(userForgetPassword(values, toastr, props.router.navigate))
  }

  const initialState = {
    email: '',
  }

  return (
    <>
      <SEO title="Reset password" />
      <div className="account-pages auth-wrapper">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <AuthHeader headerText="Reset password" />
                <CardBody className="pt-0">
                  <AuthLogo />
                  <div className="p-2">
                    <Formik
                      initialValues={initialState}
                      onSubmit={handleSubmit}
                      validationSchema={forgotPwValidationSchema}
                      enableReinitialize
                    >
                      {({handleChange, handleSubmit, values, errors, handleBlur, touched}) => {
                        const isInvalid = (field) => touched?.[field] && !!errors?.[field]
                        const isAllFilled = () => Object.keys(values).some((key) => !values[key]?.length)

                        return (
                          <Form className="form-horizontal" onSubmit={handleSubmit}>
                            <div className="mb-4">
                              <Label className={`form-label ${isInvalid('Email') && 'text-danger'}`}>Email</Label>
                              <Input
                                name="email"
                                className="form-control"
                                placeholder="Enter email"
                                type="text"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email || ''}
                                invalid={isInvalid('email')}
                              />

                              <ErrorMessage name="email" render={FormError} />
                            </div>
                            <Row className="mb-3">
                              <Col xs={12} className="text-center w-100">
                                <button className="btn btn-primary w-100 mb-3" type="submit" disabled={isAllFilled()}>
                                  Send restore link
                                </button>
                                <Link to="/login" className="font-weight-medium text-primary text-center">
                                  Back to sign in
                                </Link>{' '}
                              </Col>
                            </Row>
                          </Form>
                        )
                      }}
                    </Formik>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

ForgetPasswordPage.propTypes = {
  router: PropTypes.shape({
    navigate: PropTypes.func,
  }),
}

export default withRouter(ForgetPasswordPage)
