import React, {useState} from 'react'
import {Tooltip} from 'reactstrap'
import Icon from '../Icon'
import PropTypes from 'prop-types'

const InfoText = ({children, className, infoContent = null}) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggleTooltip = () => setIsOpen((prev) => !prev)

  return (
    <div className={className}>
      <div className="info-text__head" id="target-head">
        {children}
        <Icon icon="info" />
      </div>

      <Tooltip
        isOpen={isOpen}
        target="target-head"
        positionFixed={true}
        toggle={toggleTooltip}
        placement="bottom"
        tipPointerPosition="Middle"
        className="info-text__tooltip"
      >
        {infoContent}
      </Tooltip>
    </div>
  )
}

InfoText.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  infoContent: PropTypes.node,
}

export default InfoText
