import React, {useEffect, useRef, useCallback} from 'react'
import {NavLink, useLocation} from 'react-router-dom'
import PropTypes from 'prop-types'
import SimpleBar from 'simplebar-react'
import {withTranslation} from 'react-i18next'

import {sidebarLinks} from 'constants/sidebarLinks'

import withRouter from '../Common/withRouter'
import Icon from '../Icon'

const SidebarContent = (props) => {
  const ref = useRef()
  const addClasses = (el, classes) => {
    if (el) {
      classes.forEach((className) => el.classList.add(className))
    }
  }

  const activateParentDropdown = useCallback((item) => {
    addClasses(item, ['active'])

    let parents = []
    let parent = item.parentElement

    while (parent) {
      parents.push(parent)
      parent = parent.parentElement
    }

    parents.forEach((parent, index) => {
      if (index === 0) {
        addClasses(parent, ['mm-active'])
      } else if (index === 1) {
        const parent2El = parent.childNodes[1]
        if (parent2El?.id !== 'side-menu') {
          addClasses(parent2El, ['mm-show'])
        }
      } else {
        addClasses(parent, ['mm-show'])
      }
    })

    return false
  }, [])

  const removeActivation = (items) => {
    for (const item of items) {
      const parent = item.parentElement

      if (item?.classList.contains('active')) {
        item.classList.remove('active')
      }

      if (parent) {
        const parent2El = parent?.childNodes?.[1] ? parent.childNodes[1] : null
        if (parent2El && parent2El.id !== 'side-menu') {
          parent2El.classList.remove('mm-show')
        }

        parent.classList.remove('mm-active')
        const parent2 = parent.parentElement

        if (parent2) {
          parent2.classList.remove('mm-show')

          const parent3 = parent2.parentElement
          if (parent3) {
            parent3.classList.remove('mm-active') // li
            if (parent3.childNodes[0]) {
              parent3.childNodes[0].classList.remove('mm-active')
            }

            const parent4 = parent3.parentElement // ul
            if (parent4) {
              parent4.classList.remove('mm-show') // ul
              const parent5 = parent4.parentElement
              if (parent5) {
                parent5.classList.remove('mm-show') // li
                if (parent5.childNodes[0]) {
                  parent5.childNodes[0].classList.remove('mm-active') // a tag
                }
              }
            }
          }
        }
      }
    }
  }

  const path = useLocation()
  const activeMenu = useCallback(() => {
    const pathName = path.pathname
    let matchingMenuItem = null
    const ul = document.getElementById('side-menu')
    const items = ul.getElementsByTagName('a')
    removeActivation(items)
    for (const item of items) {
      if (pathName === item.pathname) {
        matchingMenuItem = item
        break
      }
    }

    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  }, [path.pathname, activateParentDropdown])

  useEffect(() => {
    activeMenu()
  }, [])

  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'})
    activeMenu()
  }, [activeMenu])

  return (
    <SimpleBar className="h-100" ref={ref}>
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu">
          {sidebarLinks.map((link) => {
            return (
              <li key={link.to}>
                <NavLink
                  to={link.to}
                  className="waves-effect sidebar-link text-nowrap "
                  activeclassname="active-sidebar-link"
                >
                  <Icon icon={link.icon} isActive={link.to === props.router.location.pathname} />
                  <span className="ms-2">{link.label}</span>
                </NavLink>
              </li>
            )
          })}
        </ul>
      </div>
    </SimpleBar>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  router: PropTypes.object,
}

export default withRouter(withTranslation()(SidebarContent))
