import React from 'react'
import PropTypes from 'prop-types'
import {Row, Col, CardBody, Card, Container, Form, Input, Label} from 'reactstrap'
import {useDispatch} from 'react-redux'
import {Link} from 'react-router-dom'
import {ErrorMessage, Formik} from 'formik'
import lock from 'assets/images/ic-lock.svg'
import withRouter from 'components/Common/withRouter'
import PasswordInput from 'components/Common/PasswordInput'
import FormError from 'components/Common/FormError'
import SEO from 'components/Common/SEO'
import {loginValidationSchema} from 'constants/validationSchemes'
import {loginUser} from 'store/auth/login/actions'
import AuthHeader from './components/AuthHeader'
import AuthLogo from './components/AuthLogo'

const Login = (props) => {
  const dispatch = useDispatch()

  const trimPassword = (values) => {
    return {
      ...values,
      password: values?.password?.trim(),
    }
  }

  const initialValues = {
    email: '',
    password: '',
  }

  const handleSubmit = (values) => {
    const newValues = trimPassword(values)
    dispatch(loginUser(newValues, props.router.navigate))
  }

  return (
    <>
      <SEO title="Sign In" />
      <div className="auth-wrapper">
        <Container>
          <Row className="justify-content-center ">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden auth-card">
                <AuthHeader headerText="Sign in to Yourstotell Admin panel" />
                <CardBody className="pt-0">
                  <AuthLogo />
                  <div className="p-2">
                    <Formik
                      initialValues={initialValues}
                      onSubmit={handleSubmit}
                      validationSchema={loginValidationSchema}
                      enableReinitialize
                    >
                      {({handleSubmit, handleBlur, handleChange, values, errors, touched}) => {
                        const isInvalid = (field) => touched?.[field] && !!errors?.[field]
                        const isAllFilled = () => Object.keys(values).some((key) => !values[key]?.length)
                        return (
                          <Form className="form-horizontal" onSubmit={handleSubmit}>
                            <div className="mb-4">
                              <Label className={`form-label ${isInvalid('email') && 'text-danger'}`}>Email</Label>
                              <Input
                                name="email"
                                className="form-control"
                                placeholder="Enter email"
                                type="text"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values?.email}
                                invalid={isInvalid('email')}
                              />
                              <ErrorMessage name="email" render={FormError} />
                            </div>

                            <div className="mb-3 pb-2">
                              <Label className={`form-label ${isInvalid('password') && 'text-danger'}`}>Password</Label>
                              <PasswordInput
                                name="password"
                                value={values?.password}
                                placeholder="Enter password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={isInvalid('password')}
                                error={<ErrorMessage name="password" render={FormError} />}
                              />
                            </div>

                            <div className="mt-4 d-grid">
                              <button className="btn btn-primary btn-block" type="submit" disabled={isAllFilled()}>
                                Sign In
                              </button>
                            </div>

                            <div className="mt-4 ">
                              <Link to="/forgot-password" className=" d-flex justify-content-center align-items-center">
                                <img src={lock} alt="Forgot password" height="21" className="me-2" />
                                <span className="text-muted">Forgot your password?</span>
                              </Link>
                            </div>
                          </Form>
                        )
                      }}
                    </Formik>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default withRouter(Login)

Login.propTypes = {
  router: PropTypes.shape({
    navigate: PropTypes.func,
  }),
}
