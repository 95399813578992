import React from 'react'
import {Container, Row} from 'reactstrap'
import LinkCard from './Common/LinkCard'
import PropTypes from 'prop-types'

const LinkStatusContainer = ({title, subtitle, children, success = false}) => {
  return (
    <div className="account-pages auth-wrapper">
      <Container>
        <Row className="justify-content-center ">
          <LinkCard title={title} subtitle={subtitle} success={success}>
            {children}
          </LinkCard>
        </Row>
      </Container>
    </div>
  )
}

LinkStatusContainer.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  children: PropTypes.node,
  success: PropTypes.bool,
}

export default LinkStatusContainer
