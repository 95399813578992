import React, {useEffect, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {Link, useNavigate} from 'react-router-dom'
import {withTranslation} from 'react-i18next'

import logo from 'assets/images/ic-y-letter.svg'
import logoutIcon from 'assets/images/ic-logout.svg'
import {showRightSidebarAction, toggleLeftmenu, changeSidebarType, logoutUser} from 'store/actions'

import ConfirmModal from '../ConfirmModal'

const Header = () => {
  const [isOpenLogOutModal, setIsOpenLogOutModal] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const logout = () => {
    setIsOpenLogOutModal(false)
    dispatch(logoutUser(navigate))
  }
  useEffect(() => {
    const addClass = () => {
      document.body.classList.add('vertical-collpsed')
    }
    window.addEventListener('resize', () => {
      document.body.classList.add('vertical-collpsed')
    })
    return () => {
      window.removeEventListener('resize', addClass)
    }
  })

  function tToggle() {
    const body = document.body
    if (window.screen.width <= 992) {
      body.classList.toggle('sidebar-enable')
      body.classList.add('vertical-collpsed')
    } else {
      body.classList.toggle('vertical-collpsed')
      body.classList.toggle('sidebar-enable')
    }
  }

  return (
    <>
      <header id="page-topbar" className="transition-250">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box logo-wrapper-display">
              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logo} alt="" height="22" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle()
              }}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
          </div>
          <div className="d-flex">
            <button
              className="cursor-pointer me-3 d-flex align-items-center border-0 p-0 bg-transparent"
              onClick={() => setIsOpenLogOutModal(true)}
            >
              <img src={logoutIcon} alt="Log out icon" />
              <span className="ms-2">Log out</span>
            </button>
          </div>
        </div>
      </header>
      <ConfirmModal
        isOpen={isOpenLogOutModal}
        title="Log out"
        onClickCancel={() => setIsOpenLogOutModal(false)}
        submitButtonName="Yes, log out"
        submitButtonClassNames="btn btn-danger"
        cancelButtonName="No, stay"
        onClickSubmit={logout}
      >
        <span className="line-height-24 logout-font">Are you sure you want to log out?</span>
      </ConfirmModal>
    </>
  )
}

const mapStatetoProps = (state) => {
  const {layoutType, showRightSidebar, leftMenu, leftSideBarType} = state.Layout
  return {layoutType, showRightSidebar, leftMenu, leftSideBarType}
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header))
