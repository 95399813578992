import React from 'react'
import PropTypes from 'prop-types'
import {withTranslation} from 'react-i18next'
import {Table, Button, Spinner} from 'reactstrap'
import PostPreview from 'components/Common/PostPreview'
import TableNoDataPlaceholder from 'components/TableComponents/TableNoDataPlaceholder'
import TableFilters from '../../../components/TableComponents/TableFilters'
import TablePaginationFooter from '../../../components/TableComponents/TablePaginationFooter'
import {POST_TYPE} from 'constants/post'
import {POST_TYPES_LABELS} from '../../UserSubscriptionsPage/components/PostsTooltipContent'
import {USER_POSTS_FILTERS} from '../../../constants/userManagement'
import {POSTS_PER_PAGE} from '../../../api/hooks/useGetPostsByUserId'
import dateByFormat from 'helpers/dateByFormat'
import {generatePostAlt} from '../../../helpers/generatePostAlt'

const ProfilePosts = ({
  t,
  loading,
  posts,
  setActivePage,
  onPostView,
  onPostDownload,
  onPostDeleteClick,
  filters,
  onChangeFilters,
  pagination,
}) => {
  const handleChangePage = ({selected}) => {
    if (!isNaN(selected)) {
      setActivePage(selected + 1)
    }
  }
  let tableContent
  const isTableShown = posts.length > 0
  if (loading) {
    tableContent = (
      <div className="mt-4 d-flex justify-content-center">
        <Spinner />
      </div>
    )
  } else if (isTableShown) {
    tableContent = (
      <Table>
        <thead className="profilePosts-thead">
          <tr className="text-nowrap">
            <th>{t('Post preview')}</th>
            <th>{t('Post title')}</th>
            <th>{t('Folder')}</th>
            <th>{t('Type')}</th>
            <th>{t('Date of creation')}</th>
            <th>
              <div className="profilePosts-action-btns-box">
                <div className="profilePosts-action-btns">{t('Action')}</div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {posts.map((post) => {
            return (
              <tr key={post.id}>
                <td className="centered-td">
                  <PostPreview
                    type={post.type}
                    src={post?.image?.compactPath ?? post?.video?.compactPath}
                    alt={generatePostAlt(post)}
                  />
                </td>
                <td className="centered-td">
                  <div lang="en" className="profilePosts-td-max-width" style={{maxWidth: '230px', width: '100%'}}>
                    {post?.title ?? '-'}
                  </div>
                </td>
                <td className="centered-td">
                  <div lang="en" className="profilePosts-td-max-width">
                    {post?.breadcrumbs}
                  </div>
                </td>
                <td className="centered-td">
                  <div lang="en" style={{minWidth: '100px'}}>
                    {POST_TYPES_LABELS[post?.type] ?? '-'}
                  </div>
                </td>
                <td className="centered-td">
                  <div lang="en" className="min-width-250">
                    {post?.createdAt ? dateByFormat(post?.createdAt) : ''}
                  </div>
                </td>
                <td className="centered-td">
                  <div className="profilePosts-action-btns-box">
                    <div className="profilePosts-action-btns">
                      {post.type === POST_TYPE.Text ? (
                        <Button
                          className="profilePosts-action-view"
                          color="primary"
                          onClick={onPostView.bind(null, post.id)}
                        >
                          {t('View')}
                        </Button>
                      ) : (
                        <Button
                          className="profilePosts-action-view"
                          color="primary"
                          onClick={onPostDownload.bind(null, post.id)}
                        >
                          {t('Download')}
                        </Button>
                      )}
                      <Button color="danger" onClick={onPostDeleteClick.bind(null, post.id)}>
                        {t('Delete')}
                      </Button>
                    </div>
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    )
  } else {
    tableContent = <TableNoDataPlaceholder classnames="profilePosts-empty" text={t('No posts found')} />
  }

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center pb-3 flex-wrap">
        <h4>{t('All posts')}</h4>

        <TableFilters structure={USER_POSTS_FILTERS} onApplyFilter={onChangeFilters} initialFilters={filters} />
      </div>

      <div className="overflow-auto">{tableContent}</div>

      <div className="profilePosts-pagination">
        <TablePaginationFooter
          classnames={!loading && posts.length ? 'd-flex' : 'd-none'}
          handleChangePage={handleChangePage}
          total={pagination?.totalCount}
          currentOffset={pagination?.currentOffset}
          limit={POSTS_PER_PAGE}
        />
      </div>
    </div>
  )
}

ProfilePosts.propTypes = {
  loading: PropTypes.bool,
  posts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      type: PropTypes.string,
      image: PropTypes.shape({}),
      video: PropTypes.shape({}),
      createdAt: PropTypes.string,
      album: PropTypes.string,
    }),
  ),
  setActivePage: PropTypes.func,
  onPostView: PropTypes.func,
  onPostDeleteClick: PropTypes.func,
  onPostDownload: PropTypes.func,
  pagination: PropTypes.shape({
    nextOffset: PropTypes.number,
    totalCount: PropTypes.number,
    currentOffset: PropTypes.number,
  }),
  t: PropTypes.func,
  onChangeFilters: PropTypes.func,
  filters: PropTypes.shape({}),
}
export default withTranslation()(ProfilePosts)
