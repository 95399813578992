import React, {useState, forwardRef, useEffect} from 'react'
import DatePicker from 'react-datepicker'
import PropTypes from 'prop-types'

import 'react-datepicker/dist/react-datepicker.css'

import {
  FREE_SUBSCRIPTION_LIMIT_IN_DAYS,
  MONTHS_SHORT,
  NUMBER_OF_HOURS_IN_DAY,
  NUMBER_OF_MILLISECONDS_IN_SECOND,
  NUMBER_OF_MINUTES_IN_HOUR,
  NUMBER_OF_SECONDS_IN_MINUTE,
} from 'constants/dateFormats'

const CustomInput = forwardRef(({onClick, openButtonClassName, openButtonTitle, endDateOnly, setRefresh}, ref) => (
  <button
    className={`react-datepicker__open-button react-datepicker__open-button-${openButtonClassName}`}
    onClick={() => {
      onClick()
      if (endDateOnly) {
        setRefresh((prevState) => !prevState)
      }
    }}
    ref={ref}
  >
    {openButtonTitle}
  </button>
))

const MyContainer = ({className, children, closeDatePicker}) => {
  return (
    <div className={className}>
      <div className="react-datepicker__top-section d-flex justify-content-between">
        <div className="react-datepicker__top-section__text">Select date</div>
        <button className="react-datepicker__top-section__close-button" onClick={closeDatePicker}>
          &times;
        </button>
      </div>
      <div className="react-datepicker__children-container">{children}</div>
    </div>
  )
}

const DateRangePickerWithoutInput = ({
  openButtonTitle,
  openButtonClassName,
  onApplyChanges,
  loading,
  start = null,
  end = null,
  endDateOnly = false,
  subscriptionId = undefined,
}) => {
  const calRef = React.useRef()

  const [refresh, setRefresh] = useState(false)
  const [dateRange, setDateRange] = useState([])
  const [startDate, endDate] = dateRange

  useEffect(() => {
    if (!start && !end) {
      setDateRange([null, null])
      return
    }

    const startDateObj = new Date(start)
    const endDateObj = new Date(end)

    setDateRange([startDateObj, endDateObj])
  }, [start, end, refresh])

  const handleCalendarOpen = () => {
    document.addEventListener(
      'touchstart',
      (event) => {
        event.stopPropagation()
      },
      true,
    )
  }

  const getMaxDate = (date) => {
    if (!date) {
      return date
    }
    const startTime = new Date(date).getTime()

    const numberOfMillisecondsInDay =
      NUMBER_OF_HOURS_IN_DAY *
      NUMBER_OF_MINUTES_IN_HOUR *
      NUMBER_OF_SECONDS_IN_MINUTE *
      NUMBER_OF_MILLISECONDS_IN_SECOND

    return new Date(startTime + (FREE_SUBSCRIPTION_LIMIT_IN_DAYS - 1) * numberOfMillisecondsInDay)
  }

  const maxDate = React.useMemo(() => getMaxDate(startDate), [startDate])

  const closeDatePicker = () => calRef.current.setOpen(false)

  return (
    <DatePicker
      ref={calRef}
      selectsRange={true}
      startDate={startDate}
      endDate={endDate}
      onChange={(update) => {
        if (endDateOnly) {
          setDateRange([new Date(start), update[0]])
        } else {
          setDateRange(update)
        }
      }}
      withPortal
      customInput={
        <CustomInput
          openButtonClassName={openButtonClassName}
          openButtonTitle={openButtonTitle}
          endDateOnly={endDateOnly}
          setRefresh={setRefresh}
        />
      }
      onCalendarOpen={handleCalendarOpen}
      calendarContainer={(props) => <MyContainer {...props} closeDatePicker={closeDatePicker} />}
      shouldCloseOnSelect={false}
      minDate={endDateOnly ? new Date(start) : new Date()}
      maxDate={maxDate}
      renderCustomHeader={({
        date,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
        prevYearButtonDisabled,
        nextYearButtonDisabled,
        increaseYear,
        decreaseYear,
      }) => (
        <div className="d-flex justify-content-between react-datepicker__current-month">
          <div>
            <button
              className="react-datepicker__navigation__button"
              onClick={decreaseYear}
              disabled={prevYearButtonDisabled}
            >
              {'<<'}
            </button>
            <button
              className="react-datepicker__navigation__button"
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
            >
              {'<'}
            </button>
          </div>
          <p>
            {MONTHS_SHORT[date.getMonth()]} {date.getFullYear()}
          </p>

          <div>
            <button
              className="react-datepicker__navigation__button"
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
            >
              {'>'}
            </button>
            <button
              className="react-datepicker__navigation__button"
              onClick={increaseYear}
              disabled={nextYearButtonDisabled}
            >
              {'>>'}
            </button>
          </div>
        </div>
      )}
    >
      <button
        className="react-datepicker__ok-button"
        onClick={() => {
          onApplyChanges(dateRange, closeDatePicker, subscriptionId)
        }}
        disabled={!dateRange[0] || !dateRange[1]}
      >
        {loading ? 'Processing' : 'Apply'}
      </button>
    </DatePicker>
  )
}

DateRangePickerWithoutInput.propTypes = {
  openButtonTitle: PropTypes.string,
  openButtonColor: PropTypes.string,
  onApplyChanges: PropTypes.func,
  loading: PropTypes.bool,
  start: PropTypes.string,
  end: PropTypes.string,
  endDateOnly: PropTypes.bool,
  subscriptionId: PropTypes.number,
  openButtonClassName: PropTypes.string,
}
CustomInput.propTypes = {
  onClick: PropTypes.func,
  openButtonClassName: PropTypes.string,
  openButtonTitle: PropTypes.string,
  endDateOnly: PropTypes.bool,
  setRefresh: PropTypes.func,
}
MyContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  closeDatePicker: PropTypes.func,
}

export default DateRangePickerWithoutInput
