import React from 'react'
import {Spinner, Table} from 'reactstrap'
import TableHeader from './TableComponents/TableHeader'
import TableBody from './TableComponents/TableBody'
import TableNoDataPlaceholder from './TableComponents/TableNoDataPlaceholder'
import TablePaginationFooter from './TableComponents/TablePaginationFooter'
import PropTypes from 'prop-types'

const ReportedUsersTable = ({
  loading,
  data,
  hideLoader,
  noDataText,
  headerStructure,
  onClickHeader,
  tableDataStructure,
  handleChangePage,
  pagination,
  paginationLimit,
}) => {
  return (
    <>
      <section className="d-flex flex-column justify-content-between">
        <div className="mt-2 pb-2">
          {loading && (
            <div className="patients-table d-flex justify-content-center align-items-center">
              {!hideLoader && <Spinner className="mx-auto" color="info" />}
            </div>
          )}
          {!loading && (
            <>
              {data?.length > 0 && (
                <div className="overflow-auto">
                  <Table className="table mb-0">
                    <TableHeader columns={headerStructure} clickCellAction={onClickHeader} />
                    <TableBody data={data} structure={tableDataStructure} />
                  </Table>
                </div>
              )}

              {data?.length === 0 && <TableNoDataPlaceholder text={noDataText} />}
            </>
          )}
        </div>
      </section>
      {!loading && data?.length > 0 && (
        <section className="mt-4">
          <TablePaginationFooter
            classnames={'d-flex'}
            handleChangePage={handleChangePage}
            total={pagination?.totalCount}
            currentOffset={pagination.currentOffset}
            limit={paginationLimit}
          />
        </section>
      )}
    </>
  )
}

ReportedUsersTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  hideLoader: PropTypes.bool,
  noDataText: PropTypes.string,
  headerStructure: PropTypes.array.isRequired,
  onClickHeader: PropTypes.func,
  tableDataStructure: PropTypes.array.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  pagination: PropTypes.shape({
    totalCount: PropTypes.number.isRequired,
    currentOffset: PropTypes.number.isRequired,
  }),
  paginationLimit: PropTypes.number.isRequired,
}

export default ReportedUsersTable
