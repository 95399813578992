import React from 'react'
import ReportBadge from 'components/ReportBadge'
import {REPORT_MANAGEMENT_DATE_FORMAT} from 'constants/dateFormats'
import {NOT_DEFINED} from 'constants/tableConstants'
import dateByFormat from 'helpers/dateByFormat'

export const POST_REPORTEDS_TABLE_HEADER = [
  {
    label: 'Report message',
    sortOption: false,
    classname: 'font-size-13 text-start table-header name disable-word-wrap',
  },
  {
    label: 'Reporter name',
    sortOption: false,
    classname: 'font-size-13 text-start table-header number disable-word-wrap',
  },
  {
    label: 'Date / time',
    sortOption: true,
    classname: 'font-size-13 table-header text-start join-date disable-word-wrap',
    sort: 'CreatedAt',
    sortDirection: NOT_DEFINED,
  },
  {
    label: 'Report status',
    sortOption: false,
    classname: 'font-size-13 text-start table-header status disable-word-wrap',
  },
]

export const POST_REPORTEDS_TABLE_DATA_STRUCTURE = [
  {
    name: 'message',
    classname: 'font-size-13 min-width-250 user-row text-start text-wrap border-top centered-td',
    keyProp: 'message',
    Component: ({value}) => (
      <div lang="en" className="postReportsPage_td_message">
        {value || '-'}
      </div>
    ),
  },
  {
    name: 'reporterName',
    classname: 'font-size-13 user-row text-start border-top centered-td',
    keyProp: 'reporterName',
    Component: ({value}) => (
      <div lang="en" className="postReportsPage_td_message">
        {value || '-'}
      </div>
    ),
  },
  {
    name: 'createdAt',
    classname: 'font-size-13 user-row text-start border-top date-table-cell centered-td',
    keyProp: 'createdAt',
    Component: ({value}) => dateByFormat(value, REPORT_MANAGEMENT_DATE_FORMAT),
  },
  {
    name: 'viewed',
    classname: 'user-row text-start border-top centered-td',
    keyProp: 'viewed',
    Component: ({value}) => <ReportBadge value={value ? 'Viewed' : 'New'} color={value ? 'info' : 'danger'} />,
  },
]
