import {USER_PLAN_COLORS, USER_STATUS_BADGE_COLORS} from 'constants/userManagement'
import {REPORTED_STATUS_BADGE_COLORS, USER_REPORTS_STATUS_BADGE_COLORS} from 'constants/reportManagement'
import {SUBSCRIPTION_STATUS_BADGE_COLORS} from 'constants/subscriptionManagement'

export const getStatusBadgeColor = (status) => {
  return USER_STATUS_BADGE_COLORS?.[status] || 'secondary'
}

export const getReportBadgeColor = (status) => {
  return REPORTED_STATUS_BADGE_COLORS?.[status] || 'secondary'
}

export const getUserReportBadgeColor = (status) => {
  return USER_REPORTS_STATUS_BADGE_COLORS?.[status] || 'secondary'
}

export const getSubscriptionStatusBadgeColor = (status) => {
  return SUBSCRIPTION_STATUS_BADGE_COLORS?.[status] || 'secondary'
}

export const getPlanBadgeColor = (status) => USER_PLAN_COLORS?.[status] || 'transparent'
