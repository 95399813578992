import React from 'react'
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap'
import _ from 'lodash'
import PropTypes from 'prop-types'

const ConfirmModal = ({
  isOpen,
  toggle,
  title,
  cancelButtonName = 'Cancel',
  cancelButtonClassNames = 'btn btn-outline-secondary',
  onClickCancel = null,
  submitButtonName = 'Submit',
  submitButtonClassNames = 'btn btn-primary',
  onClickSubmit = null,
  backdropAction = false,
  isLoading = false,
  children,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={backdropAction ? toggle || onClickCancel : _.noop} centered>
      <ModalHeader toggle={toggle || onClickCancel}>
        <span className="logout-font">{title}</span>
      </ModalHeader>
      <ModalBody>{children}</ModalBody>
      <ModalFooter>
        {onClickCancel && (
          <button className={cancelButtonClassNames} onClick={onClickCancel} disabled={isLoading}>
            {cancelButtonName}
          </button>
        )}
        {onClickSubmit && (
          <button className={submitButtonClassNames} onClick={onClickSubmit} disabled={isLoading}>
            {submitButtonName}
          </button>
        )}
      </ModalFooter>
    </Modal>
  )
}

ConfirmModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  title: PropTypes.string,
  cancelButtonName: PropTypes.string,
  cancelButtonClassNames: PropTypes.string,
  onClickCancel: PropTypes.func,
  submitButtonName: PropTypes.string,
  submitButtonClassNames: PropTypes.string,
  onClickSubmit: PropTypes.func,
  backdropAction: PropTypes.bool,
  isLoading: PropTypes.bool,
  children: PropTypes.node,
}

export default ConfirmModal
