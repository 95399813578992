import {POST_TYPE} from '../constants/post'

export function generatePostAlt(post) {
  if (post) {
    const {type, description, video, audio, image} = post
    const title = post.title || '-'
    switch (type) {
      case POST_TYPE.Text:
        return `Text post: "${title}". Description: "${description || 'No description provided'}".`
      case POST_TYPE.Video:
        return video ? `Video post: "${title}". Video is available.` : `Video post: "${title}". No video available.`
      case POST_TYPE.Sound:
        return audio ? `Audio post: "${title}". Audio is available.` : `Audio post: "${title}". No audio available.`
      case POST_TYPE.Image:
        return image ? `Image post: "${title}". Image is available.` : `Image post: "${title}". Image audio available.`
      default:
        return `Post: "${title}". Type: "${type}".`
    }
  }
}
