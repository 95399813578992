import React from 'react'
import {useLocation} from 'react-router-dom'
import TableRow from './TableRow'
import TableCell from './TableCell'
import PropTypes from 'prop-types'

const TableBody = ({data, structure, clickRowAction, rowClassName, ...rest}) => {
  const location = useLocation()
  return (
    <tbody>
      {data.map((dataRow) => {
        return (
          <TableRow key={dataRow.id} className={rowClassName} onClick={() => clickRowAction?.(dataRow)}>
            {structure.map((cell, index) => (
              <TableCell key={cell?.name || index} dataRow={dataRow} location={location} {...cell} {...rest} />
            ))}
          </TableRow>
        )
      })}
    </tbody>
  )
}
TableBody.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  structure: PropTypes.arrayOf(PropTypes.object),
  clickRowAction: PropTypes.func,
  rowClassName: PropTypes.string,
  rest: PropTypes.object,
}
export default TableBody
