import React from 'react'
import PropTypes from 'prop-types'

import withRouter from './Common/withRouter'

const NonAuthLayout = ({children}) => {
  return <>{children}</>
}

NonAuthLayout.propTypes = {
  children: PropTypes.any,
}

export default withRouter(NonAuthLayout)
