import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {withTranslation} from 'react-i18next'
import {Container} from 'reactstrap'
import {useGetReportedUsers} from 'api/hooks/useGetReportedUsers'
import {useGetReportedPosts} from 'api/hooks/useGetReportedPosts'
import {useGetReportedComments} from 'api/hooks/useGetReportedComments'
import {REPORTED_USERS_LIMIT, REPORTED_POSTS_LIMIT, REPORTED_COMMENTS_LIMIT} from 'constants/reportManagement'
import SEO from 'components/Common/SEO'
import withRouter from 'components/Common/withRouter'
import Tabs from 'components/Common/Tabs'
import TabContent from 'components/Common/TabContent'
import {getTab, setTab} from 'helpers/usersListOptionsState'
import ReportsManagementTab from './components/ReportsManagementTab'
import {
  REPORTED_USERS_TABLE_DATA_STRUCTURE,
  REPORTED_POSTS_TABLE_DATA_STRUCTURE,
  REPORTED_COMMENTS_TABLE_DATA_STRUCTURE,
  REPORTED_USERS_TABLE_HEADER,
  REPORTED_POSTS_TABLE_HEADER,
  REPORTED_COMMENTS_TABLE_HEADER,
} from './tableComponents/reportTablesStructure'
import PropTypes from 'prop-types'

const TABS = {
  REPORTED_USERS: 'reportedUsers',
  REPORTED_POSTS: 'reportedPosts',
  REPORTED_COMMENTS: 'reportedComments',
}

const Reports = ({t, router}) => {
  const {route, tab} = getTab()
  const [activeTab, setActiveTab] = useState(route === router.location.pathname ? tab : TABS.REPORTED_USERS)

  const onReportedUserClick = useCallback(
    (dataRow) => {
      router.navigate(`${router.location.pathname}/user/${dataRow.id}`)
    },
    [router],
  )

  const onReportedPostClick = useCallback(
    (dataRow) => {
      router.navigate(`${router.location.pathname}/post/${dataRow.id}`)
    },
    [router],
  )

  const onReportedCommentClick = useCallback(
    (dataRow) => {
      router.navigate(`${router.location.pathname}/comment/${dataRow.id}`)
    },
    [router],
  )

  const tabs = useMemo(
    () => [
      {
        id: TABS.REPORTED_USERS,
        title: t('Reported users'),
        content: (
          <ReportsManagementTab
            paginationLimit={REPORTED_USERS_LIMIT}
            getService={useGetReportedUsers}
            onClickRow={onReportedUserClick}
            tableHeaderStructure={REPORTED_USERS_TABLE_HEADER}
            tableDataStructure={REPORTED_USERS_TABLE_DATA_STRUCTURE}
            noDataText="No Results found"
            noDataTextWithFilters="No Users found"
            tabName="report_user"
          />
        ),
        onClick: () => setActiveTab(TABS.REPORTED_USERS),
      },
      {
        id: TABS.REPORTED_POSTS,
        title: t('Reported posts'),
        content: (
          <ReportsManagementTab
            paginationLimit={REPORTED_POSTS_LIMIT}
            getService={useGetReportedPosts}
            onClickRow={onReportedPostClick}
            tableHeaderStructure={REPORTED_POSTS_TABLE_HEADER}
            tableDataStructure={REPORTED_POSTS_TABLE_DATA_STRUCTURE}
            noDataText="No Posts found"
            noDataTextWithFilters="No matches"
            tabName="report_post"
          />
        ),
        onClick: () => setActiveTab(TABS.REPORTED_POSTS),
      },
      {
        id: TABS.REPORTED_COMMENTS,
        title: t('Reported comments and replies'),
        content: (
          <ReportsManagementTab
            paginationLimit={REPORTED_COMMENTS_LIMIT}
            getService={useGetReportedComments}
            onClickRow={onReportedCommentClick}
            tableHeaderStructure={REPORTED_COMMENTS_TABLE_HEADER}
            tableDataStructure={REPORTED_COMMENTS_TABLE_DATA_STRUCTURE}
            noDataText="No results found"
            noDataTextWithFilters="No results found"
            tabName="report_comment"
          />
        ),
        onClick: () => setActiveTab(TABS.REPORTED_COMMENTS),
      },
    ],
    [setActiveTab],
  )

  useEffect(() => {
    setTab(router.location.pathname, activeTab)
  }, [activeTab])

  return (
    <>
      <SEO title="Report Management" />
      <div className="page-content pb-0">
        <h3 className="mb-4 users-page-header">{t('Reports')}</h3>
        <Container fluid>
          <div className="mb-3">
            <Tabs tabs={tabs} activeTab={activeTab} />
          </div>
          <TabContent tabs={tabs} activeTab={activeTab} />
        </Container>
      </div>
    </>
  )
}

Reports.propTypes = {
  t: PropTypes.func,
  router: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
    navigate: PropTypes.func,
  }),
}

export default withTranslation()(withRouter(Reports))
