import React, {useState} from 'react'
import {withTranslation} from 'react-i18next'
import _ from 'lodash'
import {Card, Container, Spinner, Table} from 'reactstrap'
import useGetUserReportsList from 'api/hooks/useGetUserReportsList'
import Breadcrumbs from 'components/Common/Breadcrumb'
import withRouter from 'components/Common/withRouter'
import TableHeader from 'components/TableComponents/TableHeader'
import TableBody from 'components/TableComponents/TableBody'
import TablePaginationFooter from 'components/TableComponents/TablePaginationFooter'
import {USER_REPORTS_LIMIT} from 'constants/reportManagement'
import {ALLOWED_SORT_DIRECTIONS} from 'constants/tableConstants'
import isAllowedSort from 'helpers/isAllowedSort'
import {useBuildHeaderStructure, useOnClickHeaderCallback} from 'helpers/tableHelpers'

import {USER_REPORTS_DATA_STRUCTURE, USER_REPORTS_TABLE_HEADER} from './tableComponents/userReportsTableStructure'
import UserDetails from './components/UserDetails'
import {getInitialStateValues} from '../../helpers/getInitialStateValues'
import PropTypes from 'prop-types'

const ReportedUserDetails = ({t, router}) => {
  document.title = 'Reported user details'
  const {userId} = router.params
  const {offset, sorting} = getInitialStateValues()

  const [currentOffset, setCurrentOffset] = useState(offset)
  const [tableSort, setTableSort] = useState(sorting)
  const {userReports, loading, pagination} = useGetUserReportsList({
    userId,
    ...(tableSort?.OrderKey && isAllowedSort(tableSort?.OrderDirection, ALLOWED_SORT_DIRECTIONS) && tableSort),
    Offset: currentOffset,
  })

  const headerStructure = useBuildHeaderStructure(USER_REPORTS_TABLE_HEADER, tableSort)
  const onClickHeader = useOnClickHeaderCallback(setCurrentOffset, setTableSort)

  const handleChangePage = ({selected}) => {
    if (!isNaN(selected)) {
      setCurrentOffset(selected * USER_REPORTS_LIMIT)
    }
  }
  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title={t('Reports')} breadcrumbItem={t('Reported user details')} />
        <Card className="reported-user-details">
          <UserDetails hideSpinner={loading} />
          <section className="d-flex flex-column justify-content-between">
            <div className="mt-3">
              {loading && (
                <div className="patients-table d-flex justify-content-center align-items-center users-table-loader">
                  <Spinner className="mx-auto" color="info" />
                </div>
              )}
              {!loading && (
                <>
                  {userReports?.length > 0 && (
                    <div className="users-table-wrap">
                      <Table className="table mb-0 ">
                        <TableHeader columns={headerStructure} clickCellAction={onClickHeader} />
                        <TableBody data={userReports} structure={USER_REPORTS_DATA_STRUCTURE} clickRowAction={_.noop} />
                      </Table>
                    </div>
                  )}
                </>
              )}
            </div>
          </section>
          <section className="mt-4">
            <TablePaginationFooter
              classnames={userReports.length ? 'd-flex' : 'd-none'}
              handleChangePage={handleChangePage}
              total={pagination?.totalCount}
              currentOffset={pagination.currentOffset}
              limit={USER_REPORTS_LIMIT}
            />
          </section>
        </Card>
      </Container>
    </div>
  )
}

ReportedUserDetails.propTypes = {
  t: PropTypes.func,
  router: PropTypes.shape({
    params: PropTypes.shape({
      userId: PropTypes.string,
    }),
  }),
}

export default withTranslation()(withRouter(ReportedUserDetails))
