import React from 'react'
import {Col, Row} from 'reactstrap'
import * as PropsTypes from 'yup'

const AuthHeader = ({headerText}) => {
  return (
    <div className="auth-header">
      <Row>
        <Col xs={12}>
          <div className="text-light p-4 pb-5">
            <h5 className="mb-0 line-height-24">{headerText}</h5>
          </div>
        </Col>
      </Row>
    </div>
  )
}

AuthHeader.propTypes = {
  headerText: PropsTypes.string,
}

export default AuthHeader
