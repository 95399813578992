import React, {useMemo, useState} from 'react'
import PropTypes from 'prop-types'
import {withTranslation} from 'react-i18next'
import {Container} from 'reactstrap'

import Breadcrumbs from 'components/Common/Breadcrumb'
import Tabs from 'components/Common/Tabs'
import TabContent from 'components/Common/TabContent'

const TABS = {
  INFO: 'User’s info',
  SUBSCRIPTIONS: 'User’s subscription',
}

const UserSubscriptionPageView = ({errorMsg, profileDetails, profileSubscriptions}) => {
  const [activeTab, setActiveTab] = useState(TABS.INFO)

  const tabs = useMemo(
    () => [
      {
        id: TABS.INFO,
        title: TABS.INFO,
        content: profileDetails,
        onClick: () => setActiveTab(TABS.INFO),
      },
      {
        id: TABS.SUBSCRIPTIONS,
        title: TABS.SUBSCRIPTIONS,
        content: profileSubscriptions,
        onClick: () => setActiveTab(TABS.SUBSCRIPTIONS),
      },
    ],
    [profileDetails, profileSubscriptions],
  )

  return (
    <div className="page-content user-subscriptions-page">
      <Container fluid>
        <Breadcrumbs title="Subscription management" breadcrumbItem="User's subscriptions" />
        {errorMsg && (
          <div className="d-flex justify-content-center">
            <h4 className="error">{errorMsg}</h4>
          </div>
        )}

        {!errorMsg && (
          <>
            <div className="mb-3">
              <Tabs tabs={tabs} activeTab={activeTab} />
            </div>
            <TabContent tabs={tabs} activeTab={activeTab} />
          </>
        )}
      </Container>
    </div>
  )
}

export default withTranslation()(UserSubscriptionPageView)

UserSubscriptionPageView.propTypes = {
  errorMsg: PropTypes.string,
  profileDetails: PropTypes.node,
  profileSubscriptions: PropTypes.node,
}
