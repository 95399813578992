import {ReactComponent as userManagementLogo} from '../assets/images/ic-user-management.svg'
import {ReactComponent as reportManagementLogo} from '../assets/images/ic-report-management.svg'
import {ReactComponent as settingsLogo} from '../assets/images/ic-settings.svg'
import {ReactComponent as subscriptionManagementLogo} from '../assets/images/ic-subscription-management.svg'
import {ReactComponent as infoIcon} from '../assets/images/ic-info.svg'

export const USER_MANAGEMENT = 'user-management'
export const REPORT_MANAGEMENT = 'report-management'
export const SETTINGS = 'settings'
export const SUBSCRIPTION_MANAGEMENT = 'subscription-management'
export const INFO = 'info'

export const icons = {
  [USER_MANAGEMENT]: userManagementLogo,
  [REPORT_MANAGEMENT]: reportManagementLogo,
  [SETTINGS]: settingsLogo,
  [SUBSCRIPTION_MANAGEMENT]: subscriptionManagementLogo,
  [INFO]: infoIcon,
}
