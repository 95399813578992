import React from 'react'
import * as _ from 'lodash'
import PropTypes from 'prop-types'

const DefaultComponent = ({value: children}) => children

const TableCell = ({dataRow, location, classname, keyProp, Component = DefaultComponent, ...rest}) => {
  const value = _.get(dataRow, keyProp, dataRow)

  return (
    <td className={' ' + classname}>
      <Component className="table-text" value={value} location={location} dataRow={dataRow} {...rest} />
    </td>
  )
}

TableCell.propTypes = {
  dataRow: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  classname: PropTypes.string,
  keyProp: PropTypes.string.isRequired,
  Component: PropTypes.elementType,
  rest: PropTypes.object,
}

export default TableCell
