import React, {useMemo} from 'react'
import {withTranslation} from 'react-i18next'
import PropTypes from 'prop-types'

import joinClassNames from '../../../helpers/className'

export const POST_TYPES_LABELS = {
  Image: 'Photo',
  Sound: 'Audio',
  Video: 'Video',
  Text: 'Text',
}
const POST_ORDER = ['Photo', 'Video', 'Audio', 'Text']

const PostsTooltipContent = ({postsInfo}) => {
  const formattedPosts = useMemo(
    () =>
      POST_ORDER.map((postType) => {
        const originalType = Object.keys(POST_TYPES_LABELS).find((key) => POST_TYPES_LABELS[key] === postType)
        const post = postsInfo?.typesCounts.find((p) => p.type === originalType)

        return {
          label: postType,
          value: post ? post.count : '-',
        }
      }),
    [postsInfo],
  )

  return (
    <div className="post-count-info__inner">
      <div className="mb-1 fw-bold">Total posts: {postsInfo?.total || 0}</div>

      {formattedPosts.map(({value, label, className = ''}, index) => (
        <div key={index} className={joinClassNames('mb-1', className)}>
          {label}: {value}
        </div>
      ))}
    </div>
  )
}

export default withTranslation()(PostsTooltipContent)

PostsTooltipContent.propTypes = {
  postsInfo: PropTypes.shape({
    total: PropTypes.number,
    typesCounts: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.oneOf(['Image', 'Sound', 'Video', 'Text']),
        count: PropTypes.number,
      }),
    ),
  }),
}
