import {Badge} from 'reactstrap'
import React from 'react'
import PropTypes from 'prop-types'

const ReportBadge = ({value, color}) => {
  return (
    <Badge color={color} className="font-size-10 font-color-white ml-2 text-center user-status-badge">
      {value}
    </Badge>
  )
}

ReportBadge.propTypes = {
  value: PropTypes.string,
  color: PropTypes.string,
}

export default ReportBadge
