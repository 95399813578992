import React, {useCallback} from 'react'
import PropTypes from 'prop-types'
import {withTranslation} from 'react-i18next'
import {Formik} from 'formik'
import {Container} from 'reactstrap'
import {useChangeEmail} from 'api/hooks/useChangeEmail'
import {useChangePassword} from 'api/hooks/useChangePassword'
import toastr from 'components/toastr/index'
import {emailFormValidationSchema, passwordFormValidationSchema} from 'constants/validationSchemes'
import {emailFormInitialValues, passwordFormInitialValues} from 'constants/settingsPage'
import {USER_EMAIL} from 'constants/storageConstants'
import StorageService from 'services/StorageService'

import EmailForm from './EmailForm'
import PasswordForm from './PasswordForm'

const storageService = new StorageService()

const Settings = ({t}) => {
  const currentEmail = storageService.getValueByKey(USER_EMAIL)

  const {changeEmailFetch, loading: loadingEmail} = useChangeEmail()
  const {changePasswordFetch, loading: loadingPassword} = useChangePassword()

  const trimEmailValues = (values) => ({
    email: values?.email?.trim(),
  })

  const handleSubmitChangeEmail = useCallback((values, {resetForm}) => {
    changeEmailFetch(trimEmailValues(values)).then(() => {
      toastr.success('Verification link has been sent to new email')
      resetForm()
    })
  }, [])

  const trimPasswordValues = (values) => ({
    password: values?.password?.trim(),
    oldPassword: values?.oldPassword?.trim(),
    confirmPassword: values?.confirmPassword?.trim(),
  })

  const handleSubmitChangePassword = useCallback((values, {resetForm}) => {
    changePasswordFetch(trimPasswordValues(values)).then(() => {
      toastr.success('Verification link has been sent to your email')
      resetForm()
    })
  }, [])

  return (
    <div className="page-content">
      <Container fluid>
        <h3 className="settings-title">{t('Settings')}</h3>

        <div className="settings-container">
          <div className="settings-form-box">
            <p className="settings-subtitle">{t('Change email')}</p>
            <Formik
              initialValues={emailFormInitialValues}
              onSubmit={handleSubmitChangeEmail}
              validationSchema={emailFormValidationSchema}
            >
              {({handleSubmit}) => (
                <form onSubmit={handleSubmit} className="settings-form">
                  <EmailForm email={currentEmail} loading={loadingEmail} />
                </form>
              )}
            </Formik>
          </div>
          <div className="settings-form-box">
            <p className="settings-subtitle">{t('Change password')}</p>
            <Formik
              initialValues={passwordFormInitialValues}
              onSubmit={handleSubmitChangePassword}
              validationSchema={passwordFormValidationSchema}
            >
              {({handleSubmit}) => (
                <form onSubmit={handleSubmit} className="settings-form">
                  <PasswordForm loading={loadingPassword} />
                </form>
              )}
            </Formik>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default withTranslation()(Settings)

Settings.propTypes = {
  t: PropTypes.func,
}
