import React from 'react'
import dateByFormat from '../helpers/dateByFormat'
import formatPhoneNumber from '../helpers/formatPhoneNumber'
import insertSpaces from '../helpers/insertSpaces'
import StatusBadge from './Common/StatusBadge'
import InfoText from './InfoText'
import PostsTooltipContent from '../pages/UserSubscriptionsPage/components/PostsTooltipContent'
import {USER_MANAGEMENT_DATE_FORMAT} from '../constants/dateFormats'
import PropTypes from 'prop-types'

const UserInfo = ({user}) => {
  const infoBlocks = [
    {title: 'Status', content: <StatusBadge status={user?.status} />},
    {title: 'Name', content: user?.name ?? '-'},
    {title: 'User ID', content: user?.id ?? '-'},
    {
      title: 'Join date',
      content: user?.joinDate ? dateByFormat(user?.joinDate, USER_MANAGEMENT_DATE_FORMAT) : '-',
    },
    {
      title: 'Phone',
      content: user?.phoneNumber ? formatPhoneNumber(user?.phoneNumber) : '-',
    },
    {
      title: 'Subscription',
      content: user?.subscription ? insertSpaces(user?.subscription) : '-',
    },
    {title: 'Plan', content: user?.plan ?? '-'},
    {
      title: 'Total post’s',
      content: user?.postsCount?.total ? (
        <InfoText className="post-count-info" infoContent={<PostsTooltipContent postsInfo={user?.postsCount} />}>
          {user?.postsCount?.total}
        </InfoText>
      ) : (
        '-'
      ),
    },
    {title: 'Bio', content: user?.bio ?? '-'},
  ]

  return (
    <div className="profileDetails-info">
      {infoBlocks.map(({title, content}) => (
        <div key={title} className="profileDetails-info-line">
          <p className="profileDetails-info-title">{title}</p>
          <p className="profileDetails-info-content">{content}</p>
        </div>
      ))}
    </div>
  )
}

UserInfo.propTypes = {
  user: PropTypes.shape({
    status: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.number,
    joinDate: PropTypes.string,
    phoneNumber: PropTypes.string,
    subscription: PropTypes.string,
    plan: PropTypes.string,
    postsCount: PropTypes.shape({
      total: PropTypes.number,
    }),
    bio: PropTypes.string,
  }),
}

export default UserInfo
