import React, {useCallback, useState} from 'react'
import {withTranslation} from 'react-i18next'
import {Container} from 'reactstrap'
import {useDeletePostById} from 'api/hooks/useDeletePostById'
import {useGetPostId} from 'api/hooks/useGetPostId'
import {useReportsByPostId} from 'api/hooks/useReportsByPostId'
import SEO from 'components/Common/SEO'
import withRouter from 'components/Common/withRouter'
import Breadcrumbs from 'components/Common/Breadcrumb'
import toastr from 'components/toastr/index'
import DeleteModal from 'components/Common/DeleteModal'
import {POST_REPORTS_LIMIT} from 'constants/reportManagement'
import {downloadPost} from 'helpers/downloadPost'
import PostInfo from './components/PostInfo'
import ReportsTable from './components/ReportsTable'
import {POST_REPORTEDS_TABLE_DATA_STRUCTURE, POST_REPORTEDS_TABLE_HEADER} from './tableComponents/reportTablesStructure'
import PropTypes from 'prop-types'

const PostReports = ({t, router}) => {
  const {postId} = router.params

  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  const {post, loading} = useGetPostId(postId)
  const {deleteFetch, loading: deleteLoading} = useDeletePostById()

  const onPostDownload = useCallback(() => {
    downloadPost(post)
  }, [post])

  const onPostDelete = useCallback(() => setOpenDeleteModal(true), [])

  const onDeletePostCancel = useCallback(() => setOpenDeleteModal(false), [])

  const onDeletePostConfirm = useCallback(() => {
    deleteFetch(postId).then(() => {
      setOpenDeleteModal(false)
      router.navigate('/report-management')
      toastr.success(t('Post was deleted'))
    })
  }, [])

  return (
    <>
      <SEO title="Reported post details" />
      <div className="page-content pb-0">
        <Breadcrumbs title={t('Reports')} breadcrumbItem={t('Reported post details')} />
        <Container fluid>
          <div className="postReportsPage_container">
            <PostInfo post={post} loading={loading} onPostDownload={onPostDownload} onPostDelete={onPostDelete} />
            <ReportsTable
              postId={postId}
              paginationLimit={POST_REPORTS_LIMIT}
              getService={useReportsByPostId}
              tableHeaderStructure={POST_REPORTEDS_TABLE_HEADER}
              tableDataStructure={POST_REPORTEDS_TABLE_DATA_STRUCTURE}
              noDataText="No Reports found"
              hideLoader={loading}
            />

            <DeleteModal
              isOpen={openDeleteModal}
              onClose={onDeletePostCancel}
              onDelete={onDeletePostConfirm}
              title={t('Delete post')}
              text={t('Are you sure you want to delete post? This post will be deleted for all users with access.')}
              isLoading={deleteLoading}
            />
          </div>
        </Container>
      </div>
    </>
  )
}

PostReports.propTypes = {
  t: PropTypes.func,
  router: PropTypes.shape({
    params: PropTypes.shape({
      postId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    navigate: PropTypes.func,
  }),
}

export default withTranslation()(withRouter(PostReports))
