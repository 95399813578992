import React, {useEffect, useMemo, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import moment from 'moment'
import withRouter from 'components/Common/withRouter'
import LinkStatusContainer from 'components/LinkStatusContainer'
import toastr from 'components/toastr/index'
import SEO from 'components/Common/SEO'

import {
  ERROR_BUTTON_NAME,
  ERROR_SUBTITLE,
  EXPIRED_BUTTON_NAME,
  EXPIRED_SUBTITLE,
  FAIL_TITLE,
  INTERVAL,
  SEND_TIMEOUT,
  STORAGE_COUNTDOWN_KEY,
  SUCCESS_SUBTITLE,
  SUCCESS_TITLE,
} from 'constants/authPageConst'
import formatTimer from 'helpers/formatTimer'
import queryStringParser from 'helpers/queryStringParser'
import secondsBetweenDates from 'helpers/secondsBetweenDates'
import StorageService from 'services/StorageService'
import {userForgetPassword} from 'store/auth/forgetpwd/actions'
import PropTypes from 'prop-types'

const storageService = new StorageService()

const RecoverLink = ({router}) => {
  const [timer, setTimer] = useState(null)
  const [isCountdown, setIsCountdown] = useState(false)
  const [toManyAttempts, setToManyAttempts] = useState(false)
  const [emailSent, setEmailSent] = useState(false)

  const {email} = queryStringParser(router.location.search)

  const forgetPwStore = useSelector((state) => state?.ForgetPassword)
  const dispatch = useDispatch()

  const timerRef = useRef()

  const startCountDown = (endTime) => {
    setIsCountdown(true)
    let end = moment().add(SEND_TIMEOUT, 'seconds').toISOString()
    if (endTime) {
      end = moment(endTime).toISOString()
    }

    timerRef.current = setInterval(() => {
      setTimer(secondsBetweenDates(end))
    }, INTERVAL * 1000)

    storageService.addValueByKey(STORAGE_COUNTDOWN_KEY, end)
  }

  const stopCountDown = () => {
    clearInterval(timerRef.current)
    setTimer(null)
    storageService.deleteValueByKey(STORAGE_COUNTDOWN_KEY)
  }

  const title = useMemo(() => (emailSent && !toManyAttempts ? SUCCESS_TITLE : FAIL_TITLE), [emailSent, toManyAttempts])

  const subtitle = useMemo(() => {
    if (emailSent) {
      return toManyAttempts ? ERROR_SUBTITLE : SUCCESS_SUBTITLE(email)
    }
    return EXPIRED_SUBTITLE
  }, [emailSent, toManyAttempts, email])

  const duration = useMemo(() => moment.duration(timer || 0, 'seconds'), [timer])

  const onSend = () => {
    dispatch(userForgetPassword({email}, toastr, router.navigate, true))
  }

  useEffect(() => {
    if (timer < 0 || toManyAttempts) {
      setIsCountdown(false)
      stopCountDown()
    }
  }, [timer])

  useEffect(() => {
    const oldEndDate = storageService.getValueByKey(STORAGE_COUNTDOWN_KEY)

    if (secondsBetweenDates(oldEndDate) > 0 && !isCountdown && !toManyAttempts) {
      setTimer(secondsBetweenDates(oldEndDate))
      startCountDown(oldEndDate)
    } else {
      storageService.deleteValueByKey(STORAGE_COUNTDOWN_KEY)
    }
  }, [])

  useEffect(() => {
    if (forgetPwStore.forgetSuccessMsg) {
      setEmailSent(true)
      startCountDown()
    }
    if (forgetPwStore.forgetError) {
      setToManyAttempts(true)
      setEmailSent(true)
    }
  }, [forgetPwStore.forgetError, forgetPwStore.forgetSuccessMsg])

  useEffect(() => {}, [])

  if (!email) {
    router.navigate('/login')
  }

  return (
    <>
      <SEO title="Expired link" />
      <LinkStatusContainer title={title} subtitle={subtitle} success={emailSent && !toManyAttempts}>
        <div className="text-center mt-3">
          {!toManyAttempts && (
            <>
              <button
                className="btn btn-primary w-100 px-4 py-2 fg-pw-error-button"
                onClick={() => onSend()}
                disabled={isCountdown}
              >
                {EXPIRED_BUTTON_NAME}
              </button>
              {isCountdown && (
                <div className="mt-2 restore-password-message-subtitle text-center text-muted">
                  Resend link in {formatTimer(duration)} sec
                </div>
              )}
            </>
          )}
          {toManyAttempts && (
            <button
              className="btn btn-primary w-100 px-4 py-2 fg-pw-error-button"
              onClick={() => router.navigate('/login')}
            >
              {ERROR_BUTTON_NAME}
            </button>
          )}
        </div>
      </LinkStatusContainer>
    </>
  )
}

RecoverLink.propTypes = {
  router: PropTypes.shape({
    navigate: PropTypes.func,
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
  }),
}
export default withRouter(RecoverLink)
