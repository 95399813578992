import React from 'react'
import {Helmet} from 'react-helmet'
import PropTypes from 'prop-types'

const SEO = ({title, description}) => (
  <Helmet>
    <title>{title}</title>
    <meta name="description" content={description} />
  </Helmet>
)

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}

export default SEO
