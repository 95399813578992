import {POST_TYPE} from 'constants/post'

import {attachFileForDownload} from './attachFileForDownload'

export const downloadPost = (post) => {
  if (post.type === POST_TYPE.Image) {
    return attachFileForDownload(post.image.originalPath)
  }
  if (post.type === POST_TYPE.Video) {
    return attachFileForDownload(post.video.originalPath)
  }
  if (post.type === POST_TYPE.Sound) {
    return attachFileForDownload(post.audio.originalPath)
  }
  console.error(`PostType: ${post.type} - not supported for download`)
}
