import React from 'react'
import PropTypes from 'prop-types'
import {withTranslation} from 'react-i18next'
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from 'reactstrap'

const DeleteModal = ({t, isOpen, onClose, onDelete, title, text, isLoading}) => {
  return (
    <Modal isOpen={isOpen} centered>
      <ModalHeader toggle={onClose}>{title}</ModalHeader>
      <ModalBody>{text}</ModalBody>
      <ModalFooter>
        <Button outline onClick={onClose} disabled={isLoading}>
          {t('Cancel')}
        </Button>
        <Button color="danger" onClick={onDelete} disabled={isLoading}>
          {t('Delete')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

DeleteModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
  title: PropTypes.string,
  text: PropTypes.string,
  isLoading: PropTypes.bool,
  t: PropTypes.func,
}

export default withTranslation()(DeleteModal)
