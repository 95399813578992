import React, {useEffect, useState} from 'react'
import {withTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import {Spinner, Button} from 'reactstrap'

import AuthService from 'api/AuthService'
import withRouter from 'components/Common/withRouter'
import LinkStatusContainer from 'components/LinkStatusContainer'
import {SESSION} from 'constants/storageConstants'
import getApiError from 'helpers/getApiError'
import queryParser from 'helpers/queryStringParser'
import StorageService from 'services/StorageService'
import PropTypes from 'prop-types'

const storageService = new StorageService()

const ChangePassword = ({t, router}) => {
  const {token = ''} = queryParser(router?.location?.search)
  const accessToken = storageService.getValueByKey(SESSION)?.accessToken

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    AuthService.confirmChangePasswordInSettings({token})
      .catch((e) => setError(getApiError(e)))
      .finally(() => setLoading(false))
  }, [token])

  const title = error ? t('Expired!') : t('Success!')
  const subTitle = error || t('Your password has been successfully changed')
  const link = accessToken ? '/settings' : '/login'

  if (loading) {
    return (
      <div className="change-password-page">
        <Spinner />
      </div>
    )
  }

  return (
    <LinkStatusContainer title={title} subtitle={subTitle} success={!error}>
      <Link to={link}>
        <Button color="primary" className="change-password-ok-btn">
          {t('Ok')}
        </Button>
      </Link>
    </LinkStatusContainer>
  )
}

ChangePassword.propTypes = {
  t: PropTypes.func,
  router: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
  }),
}

export default withTranslation()(withRouter(ChangePassword))
