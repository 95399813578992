import React, {useCallback} from 'react'
import {withTranslation} from 'react-i18next'
import {Card, Container, Spinner, Table} from 'reactstrap'
import PropTypes from 'prop-types'
import withRouter from 'components/Common/withRouter'
import SEO from 'components/Common/SEO'
import {useBuildHeaderStructure, useOnClickHeaderCallback} from 'helpers/tableHelpers'
import TableSearch from 'components/TableComponents/TableSearch'
import TableFilters from 'components/TableComponents/TableFilters'
import TableHeader from 'components/TableComponents/TableHeader'
import TableBody from 'components/TableComponents/TableBody'
import TableNoDataPlaceholder from 'components/TableComponents/TableNoDataPlaceholder'
import TablePaginationFooter from 'components/TableComponents/TablePaginationFooter'
import {
  SUBSCRIPTION_MANAGEMENT_USERS_LIMIT,
  SUBSCRIPTION_MANAGEMENT_USERS_LIST_FILTERS,
} from 'constants/subscriptionManagement'
import {
  SUBSCRIPTION_MANAGEMENT_USER_TABLE_DATA_STRUCTURE,
  SUBSCRIPTION_MANAGEMENT_USER_TABLE_HEADER,
} from './tableComponents/subscriptionsTableStructure'
import {useUsersTable} from '../../hooks/useUsersTable'
import {getInitialStateValues} from '../../helpers/getInitialStateValues'

const SubscriptionManagement = ({router}) => {
  const {offset, query, curFilters, sorting} = getInitialStateValues()

  const {
    setCurrentOffset,
    searchString,
    filters,
    setFilters,
    tableSort,
    setTableSort,
    usersList,
    pagination,
    loading,
    debounceSearch,
  } = useUsersTable({offset, query, curFilters, sorting})

  const handleChangePage = ({selected}) => {
    if (!isNaN(selected)) {
      setCurrentOffset(selected * SUBSCRIPTION_MANAGEMENT_USERS_LIMIT)
    }
  }

  const handleChangeFilters = useCallback((values) => {
    setFilters(values)
    setCurrentOffset(0)
  }, [])

  const onUserRowClick = (dataRow) => {
    router.navigate(`${router.location.pathname}/${dataRow.id}`)
  }

  const headerStructure = useBuildHeaderStructure(SUBSCRIPTION_MANAGEMENT_USER_TABLE_HEADER, tableSort)
  const onClickHeader = useOnClickHeaderCallback(setCurrentOffset, setTableSort)

  return (
    <>
      <SEO title="Subscription Management" />
      <div className="page-content pb-0">
        <h3 className="mb-4 users-page-header">Subscription Management</h3>
        <Container fluid>
          <Card className="p-4 pb-3 users-page-content">
            <section className="d-flex justify-content-between pb-3 flex-wrap">
              <TableSearch onChange={debounceSearch} initialValue={searchString} />
              <TableFilters
                structure={SUBSCRIPTION_MANAGEMENT_USERS_LIST_FILTERS}
                onApplyFilter={handleChangeFilters}
                initialFilters={filters}
              />
            </section>
            <section className="d-flex flex-column justify-content-between">
              <div className="mt-2">
                {loading && (
                  <div className="patients-table d-flex justify-content-center align-items-center users-table-loader">
                    <Spinner className="mx-auto" color="info" />
                  </div>
                )}
                {!loading && (
                  <>
                    {usersList?.length > 0 && (
                      <div className="users-table-wrap">
                        <Table className="table mb-0 ">
                          <TableHeader columns={headerStructure} clickCellAction={onClickHeader} />
                          <TableBody
                            data={usersList}
                            structure={SUBSCRIPTION_MANAGEMENT_USER_TABLE_DATA_STRUCTURE}
                            clickRowAction={onUserRowClick}
                          />
                        </Table>
                      </div>
                    )}
                    {!usersList.length && (
                      <TableNoDataPlaceholder classnames="users-table-loader" text="No Users found" />
                    )}
                  </>
                )}
              </div>
            </section>
            <section className="mt-4">
              <TablePaginationFooter
                classnames={!loading && usersList.length ? 'd-flex' : 'd-none'}
                handleChangePage={handleChangePage}
                total={pagination?.totalCount}
                currentOffset={pagination.currentOffset}
                limit={SUBSCRIPTION_MANAGEMENT_USERS_LIMIT}
              />
            </section>
          </Card>
        </Container>
      </div>
    </>
  )
}

export default withTranslation()(withRouter(SubscriptionManagement))

SubscriptionManagement.propTypes = {
  router: PropTypes.object,
}
