import React, {useState} from 'react'
import xMarkIcon from 'assets/images/ic-xmark.svg'
import PropTypes from 'prop-types'

const TableSearch = ({onChange, filters, initialValue = ''}) => {
  const [searchValue, setSearchValue] = useState(initialValue)

  const handleInputChange = (e) => {
    const value = e.target.value
    setSearchValue(value)
    onChange(e, filters)
  }

  const handleClearInput = () => {
    setSearchValue('')
    onChange({target: {value: ''}}, filters)
  }

  return (
    <div className="search-box me-2 d-inline-block pb-2 pb-sm-0">
      <div className="position-relative">
        <input onChange={handleInputChange} className="search-bar" placeholder="Search" value={searchValue} />
        <i className="bx bx-search-alt search-icon" />
        {searchValue && (
          <button onClick={handleClearInput} className="xmark-icon">
            <img alt="Clear button icon" src={xMarkIcon} />
          </button>
        )}
      </div>
    </div>
  )
}

TableSearch.propTypes = {
  onChange: PropTypes.func,
  filters: PropTypes.object,
  initialValue: PropTypes.string,
}

export default TableSearch
