import React, {useCallback, useState, useEffect} from 'react'
import {ALLOWED_SORT_DIRECTIONS} from 'constants/tableConstants'
import isAllowedSort from 'helpers/isAllowedSort'
import getNewSortDirection from 'helpers/getNewSortDirection'
import {useBuildHeaderStructure} from '../../../helpers/tableHelpers'
import ReportedUsersTable from '../../../components/ReportedUsersTable'
import {getInitialStateValues} from '../../../helpers/getInitialStateValues'
import PropTypes from 'prop-types'

const ReportsTable = ({
  commentId,
  paginationLimit,
  getService,
  tableHeaderStructure,
  tableDataStructure,
  noDataText,
  hideLoader = false,
}) => {
  const {offset, sorting} = getInitialStateValues()
  const [currentOffset, setCurrentOffset] = useState(offset)
  const [tableSort, setTableSort] = useState(sorting)
  const {data, pagination, loading} = getService(commentId, {
    ...(tableSort?.OrderKey && isAllowedSort(tableSort?.OrderDirection, ALLOWED_SORT_DIRECTIONS) && tableSort),
    Offset: currentOffset,
  })

  const handleChangePage = ({selected}) => {
    if (!isNaN(selected)) {
      setCurrentOffset(selected * paginationLimit)
    }
  }

  const headerStructure = useBuildHeaderStructure(tableHeaderStructure, sorting)
  const onClickHeader = useCallback(
    (column) => {
      if (column.sortOption) {
        const newDirection = getNewSortDirection(column?.sortDirection)

        setTableSort({
          OrderKey: column?.sort,
          OrderDirection: newDirection,
        })
        setCurrentOffset(0)
      }
    },
    [setTableSort, setCurrentOffset],
  )

  useEffect(() => {
    if (pagination?.totalCount === 0) return

    if (currentOffset >= pagination?.totalCount) {
      handleChangePage({selected: Math.ceil(pagination?.totalCount / paginationLimit) - 1})
    }
  }, [pagination, currentOffset, paginationLimit])

  return (
    <ReportedUsersTable
      loading={loading}
      data={data}
      hideLoader={hideLoader}
      noDataText={noDataText}
      headerStructure={headerStructure}
      onClickHeader={onClickHeader}
      tableDataStructure={tableDataStructure}
      handleChangePage={handleChangePage}
      pagination={pagination}
      paginationLimit={paginationLimit}
    />
  )
}

ReportsTable.propTypes = {
  commentId: PropTypes.string,
  paginationLimit: PropTypes.number,
  getService: PropTypes.func,
  tableHeaderStructure: PropTypes.array,
  tableDataStructure: PropTypes.object,
  noDataText: PropTypes.string,
  hideLoader: PropTypes.bool,
}

export default ReportsTable
