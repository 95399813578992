import {useState, useEffect, useCallback} from 'react'

import getApiError from 'helpers/getApiError'

import PostService from '../PostService'

export const POSTS_PER_PAGE = 10

export const useGetPostsByUserId = (userId, {page = 1, filters}) => {
  const [posts, setPosts] = useState([])
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [totalPages, setTotalPages] = useState(1)
  const [offset, setOffset] = useState(0)
  const [paginationData, setPaginationData] = useState({
    nextOffset: 0,
    totalCount: 0,
    currentOffset: 0,
  })

  const fetchPosts = useCallback(async () => {
    setLoading(true)
    return PostService.getPostsByUserId(userId, offset, POSTS_PER_PAGE, filters)
      .then(({data, pagination}) => {
        setPosts(data)
        setTotalPages(Math.ceil(pagination?.totalCount / POSTS_PER_PAGE))
        setPaginationData((prevState) => ({
          nextOffset: pagination?.nextOffset,
          totalCount: pagination?.totalCount,
          currentOffset: Number.isInteger(offset) ? offset : prevState.nextOffset,
        }))
      })
      .catch((error) => setError(getApiError(error)))
      .finally(() => setLoading(false))
  }, [userId, offset, filters])

  useEffect(() => {
    if (!userId) return

    fetchPosts()
  }, [fetchPosts])

  useEffect(() => {
    setOffset((page - 1) * POSTS_PER_PAGE)
  }, [page])

  return {posts, totalPages, error, loading, fetchPosts, paginationData}
}
