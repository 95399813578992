import React from 'react'
import PropTypes from 'prop-types'
import {withTranslation} from 'react-i18next'
import {Spinner} from 'reactstrap'
import ImageSquare from 'components/Common/ImageSquare'
import UserInfo from '../../../components/UserInfo'

const ProfileDetails = ({loading, user, showTitle = true, hideLoader = false}) => {
  if (loading) {
    return <div className="d-flex justify-content-center">{!hideLoader && <Spinner color="info" />}</div>
  }

  return (
    <div>
      {showTitle && <h4 className="font-size-15">Profile details</h4>}
      <div className={`profileDetails ${!showTitle && 'pt-0'}`}>
        <div className="profileDetails-image">
          <ImageSquare src={user?.avatar?.originalPath ?? ''} withLoader={true} />
        </div>
        <UserInfo user={user} />
      </div>
    </div>
  )
}

export default withTranslation()(ProfileDetails)

ProfileDetails.propTypes = {
  loading: PropTypes.bool,
  user: PropTypes.shape({
    avatar: PropTypes.shape({
      originalPath: PropTypes.string,
    }),
    bio: PropTypes.string,
    id: PropTypes.number,
    joinDate: PropTypes.string,
    name: PropTypes.string,
    phoneNumber: PropTypes.string,
    status: PropTypes.oneOf(['All', 'Active', 'Blocked']),
    subscription: PropTypes.oneOf(['All', 'Purchased', 'NotPurchased']),
    plan: PropTypes.string,
    postsCount: PropTypes.shape({
      total: PropTypes.number,
      typesCounts: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.oneOf(['Image', 'Sound', 'Video', 'Text']),
          count: PropTypes.number,
        }),
      ),
    }),
  }),
  hideLoader: PropTypes.bool,
  showTitle: PropTypes.bool,
}
