import React, {useState} from 'react'
import {Input} from 'reactstrap'
import _ from 'lodash'
import show from 'assets/images/ic-show.svg'
import hide from 'assets/images/ic-hide.svg'
import PropTypes from 'prop-types'

const PasswordInput = ({
  name = 'password',
  value = '',
  placeholder = 'Enter password',
  onChange = _.noop,
  onBlur = _.noop,
  invalid = false,
  error,
  ...rest
}) => {
  const [isShow, setIsShow] = useState(false)

  return (
    <div className="password-input">
      <Input
        name={name}
        value={value}
        type={isShow ? 'text' : 'password'}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        invalid={invalid}
        className="password-input-field "
        {...rest}
      />
      <button
        type="button"
        className="password-input-button"
        onClick={() => setIsShow((prev) => !prev)}
        aria-label={isShow ? 'Hide password' : 'Show password'}
        title={isShow ? 'Hide password' : 'Show password'}
      >
        <img src={isShow ? hide : show} alt={isShow ? 'Hide password' : 'Show password'} />
      </button>
      {invalid && error}
    </div>
  )
}
PasswordInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  invalid: PropTypes.bool,
  error: PropTypes.node,
  rest: PropTypes.object,
}
export default PasswordInput
