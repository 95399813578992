import React, {useCallback, useMemo, useState} from 'react'
import {withTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import moment from 'moment'

import withRouter from 'components/Common/withRouter'
import {useGetUserById} from 'api/hooks/useGetUserById'
import {useGetSubscriptionsByUserId} from 'api/hooks/useGetSubscriptionsByUserId'
import {SUBSCRIPTION_LIMIT} from 'constants/subscriptionManagement'
import {useDeleteSubscriptionById} from 'api/hooks/useDeleteSubscriptionById'
import toastr from 'components/toastr'
import ConfirmModal from 'components/ConfirmModal'
import {useGiveFreeSubscription} from 'api/hooks/useGiveFreeSubscription'
import {setEndOfDay} from 'helpers/setEndOfDay'
import {useChangeFreeSubscription} from 'api/hooks/useChangeFreeSubscription'

import UserSubscriptionsPageView from './view'
import ProfileDetails from './ProfileDetails'
import ProfileSubscriptions from './ProfileSubscriptions'

const UserSubscriptionsPage = ({router}) => {
  document.title = 'User subscriptions'

  const {userId} = router.params

  const [currentOffset, setCurrentOffset] = useState(0)
  const [cancelSubscriptionModal, setCancelSubscriptionModal] = useState(false)
  const [selectedSubscription, setSelectedSubscription] = useState(null)

  const {user, error, loading} = useGetUserById(userId)
  const {deleteSubscription, loading: loadingDeleteSubscription} = useDeleteSubscriptionById()
  const {giveFreeSubscription, loading: giveFreeSubscriptionLoading} = useGiveFreeSubscription()
  const {changeFreeSubscription, loading: changeFreeSubscriptionLoading} = useChangeFreeSubscription()
  const {
    subscriptions,
    pagination,
    loading: subscriptionsLoading,
    updateSubscriptionsList,
  } = useGetSubscriptionsByUserId({userId, Offset: currentOffset})

  const handleChangePage = ({selected}) => {
    if (!isNaN(selected)) {
      setCurrentOffset(selected * SUBSCRIPTION_LIMIT)
    }
  }

  const onGiveFreeSubscription = useCallback(
    (dateRange, closeDatePicker) => {
      if (!dateRange[0] && !dateRange[1]) return

      const today = new Date()

      let startDateSelected = new Date(dateRange[0])

      const isTodaySelected = moment(startDateSelected).isSame(today, 'day')

      if (isTodaySelected) {
        startDateSelected = moment(today).add(10, 's')
      }

      const endDateSelectedIncreasedByOneDay = setEndOfDay(dateRange[1])
      const endDateSelected = new Date(endDateSelectedIncreasedByOneDay)

      const values = {
        userId,
        startDate: startDateSelected.toISOString(),
        endDate: endDateSelected.toISOString(),
      }

      const giveFreeSubscriptionSuccessActions = () => {
        updateSubscriptionsList()
        closeDatePicker()
        toastr.success('Subscription successfully granted')
      }

      giveFreeSubscription(values, giveFreeSubscriptionSuccessActions)
    },
    [giveFreeSubscription, setEndOfDay, updateSubscriptionsList],
  )

  const onConfirmSubscriptionCancelClick = useCallback(() => {
    const cancelSubscriptionSuccessActions = () => {
      setCancelSubscriptionModal(false)
      updateSubscriptionsList()
      setSelectedSubscription(null)
      toastr.success('Subscription successfully cancelled')
    }

    deleteSubscription(selectedSubscription.id, cancelSubscriptionSuccessActions)
  }, [
    selectedSubscription,
    setCancelSubscriptionModal,
    updateSubscriptionsList,
    setSelectedSubscription,
    deleteSubscription,
  ])

  const onChangeFreeSubscription = useCallback(
    (dateRange, closeDatePicker, subscriptionId) => {
      if (!dateRange[0] && !dateRange[1]) return

      const endDateSelectedIncreasedByOneDay = setEndOfDay(dateRange[1])
      const endDateSelected = new Date(endDateSelectedIncreasedByOneDay)

      const values = {
        endDate: endDateSelected.toISOString(),
      }

      const changeFreeSubscriptionSuccessActions = () => {
        updateSubscriptionsList()
        closeDatePicker()
        toastr.success('Expiration date successfully changed')
      }

      changeFreeSubscription(subscriptionId, values, changeFreeSubscriptionSuccessActions)
    },
    [changeFreeSubscription, updateSubscriptionsList, setEndOfDay],
  )

  const onRequestSubscriptionCancel = useCallback(
    (subscription) => {
      setCancelSubscriptionModal(true)
      setSelectedSubscription(subscription)
    },
    [setCancelSubscriptionModal, setSelectedSubscription],
  )

  const onRefuseSubscriptionCancel = useCallback(() => setCancelSubscriptionModal(false), [setCancelSubscriptionModal])

  const profileDetails = useMemo(() => <ProfileDetails loading={loading} user={user} />, [loading, user])

  const profileSubscriptions = useMemo(
    () => (
      <ProfileSubscriptions
        loading={subscriptionsLoading}
        subscriptions={subscriptions}
        pagination={pagination}
        handleChangePage={handleChangePage}
        onCancelSubscriptionClick={onRequestSubscriptionCancel}
        onChangeFreeSubscription={onChangeFreeSubscription}
        changeFreeSubscriptionLoading={changeFreeSubscriptionLoading}
        onGiveFreeSubscription={onGiveFreeSubscription}
        giveFreeSubscriptionLoading={giveFreeSubscriptionLoading}
      />
    ),
    [subscriptionsLoading, subscriptions, pagination],
  )

  return (
    <>
      <UserSubscriptionsPageView
        errorMsg={error}
        profileDetails={profileDetails}
        profileSubscriptions={profileSubscriptions}
      />
      <ConfirmModal
        title="Cancel subscription"
        isOpen={cancelSubscriptionModal}
        onClickSubmit={onConfirmSubscriptionCancelClick}
        onClickCancel={onRefuseSubscriptionCancel}
        submitButtonName="Yes, cancel subscription"
        submitButtonClassNames="btn btn-danger"
        cancelButtonName="No"
        isLoading={loadingDeleteSubscription}
      >
        Are you sure you want to cancel the subscription?
      </ConfirmModal>
    </>
  )
}

export default withTranslation()(withRouter(UserSubscriptionsPage))

UserSubscriptionsPage.propTypes = {
  router: PropTypes.object,
}
