import Http from './axios-config'

class PostService {
  constructor() {
    this.http = new Http()
  }

  async getPostsByUserId(id, offset = 0, limit = 10, filters = {}) {
    const params = {offset, limit, ...filters}

    return await this.http.get(`/admin-posts/${id}`, {params})
  }

  async getPostById(postId) {
    return await this.http.get(`/admin-posts/details/${postId}`)
  }

  async deletePostById(id) {
    return await this.http.delete(`/admin-posts/${id}`)
  }
}

export default new PostService()
