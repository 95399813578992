import moment from 'moment'
import {UNREACHABLE_YEAR} from '../constants/tableConstants'

const DEFAULT_FORMAT = 'DD MMM YYYY / HH:mm A'

const dateByFormat = (date, format = DEFAULT_FORMAT, emptyHolder) => {
  if (moment(date).year() > UNREACHABLE_YEAR) {
    return emptyHolder || '-'
  }
  return moment(date).isValid() ? moment(date).format(format) : '-'
}

export default dateByFormat
