import StorageService from 'services/StorageService'
import {PAGE_TAB} from 'constants/storageConstants'

const storageService = new StorageService()

export const getPageTab = (storeKey) => () => {
  const pageTab = storageService.getValueByKey(storeKey) || {}
  const {route = '', tab = ''} = pageTab || {}

  return {route, tab}
}

export const setPageTab = (storeKey) => (route, tab) => {
  storageService.deleteValueByKey(storeKey)
  storageService.addValueByKey(storeKey, {
    route,
    tab,
  })
}

export const getTab = getPageTab(PAGE_TAB)
export const setTab = setPageTab(PAGE_TAB)
