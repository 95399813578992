import React from 'react'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import logoSm from 'assets/images/ic-y-letter.svg'
import logo from 'assets/images/ic-sidebar-logo.svg'
import withRouter from 'components/Common/withRouter'
import SidebarContent from './SidebarContent'

const Sidebar = () => {
  return (
    <div className="vertical-menu transition-250-width position-fixed ">
      <div className="navbar-brand-box">
        <Link to="/" className="logo logo-dark">
          <span className="logo-sm">
            <img src={logoSm} alt="App logo" height="22" />
          </span>
          <span className="logo-lg">
            <img src={logo} alt="App logo" height="22" />
          </span>
        </Link>

        <Link to="/" className="logo logo-light">
          <span className="logo-sm">
            <img src={logoSm} alt="App logo" height="22" />
          </span>
          <span className="logo-lg">
            <img src={logo} alt="App logo" height="22" />
          </span>
        </Link>
      </div>
      <div className="h-100">
        <SidebarContent />
      </div>
      <div className="sidebar-background"></div>
    </div>
  )
}

const mapStatetoProps = (state) => {
  return {
    layout: state.Layout,
  }
}
export default connect(mapStatetoProps, {})(withRouter(withTranslation()(Sidebar)))
