import React, {useCallback, useMemo, useState, useEffect} from 'react'
import ReactPaginate from 'react-paginate'

import {MOBILE} from 'constants/breakpoints'
import useMediaQuery from 'hooks/useMediaQuery'
import {MAX_ELEMENTS_PER_PAGE} from '../../constants/tableConstants'
import PropTypes from 'prop-types'

const TablePaginationFooter = ({total, handleChangePage, currentOffset, limit, classnames}) => {
  const pagesCount = useMemo(() => Math.ceil(total / limit), [total, limit])
  const initialPage = Math.ceil(currentOffset / limit)
  const [isLastPage, setIsLastPage] = useState(false)
  const isMobile = useMediaQuery(MOBILE)

  const onChange = useCallback(
    (values) => {
      setIsLastPage(pagesCount === values?.selected + 1 && pagesCount > 0)
      handleChangePage(values)
    },
    [setIsLastPage, total],
  )

  useEffect(() => {
    if (total === 0) return

    if (currentOffset >= total) {
      handleChangePage({selected: Math.ceil(total / limit) - 1})
    }
  }, [total, currentOffset, limit])

  return (
    <div className={`pagination justify-content-end ${classnames || ''}`}>
      {total > MAX_ELEMENTS_PER_PAGE && (
        <ReactPaginate
          previousLabel={'<'}
          nextLabel={'>'}
          pageCount={pagesCount}
          marginPagesDisplayed={1}
          pageRangeDisplayed={isLastPage ? 3 : 2}
          onPageChange={onChange}
          breakClassName={'page-item'}
          breakLinkClassName={'page-link'}
          containerClassName={`pagination ${isMobile && 'pagination-sm'} overflow-auto `}
          pageClassName={'page-item'}
          pageLinkClassName={'page-link'}
          previousClassName={'custom-shevron-prev'}
          previousLinkClassName={'page-link'}
          nextClassName={'custom-shevron-next'}
          nextLinkClassName={'page-link'}
          activeClassName={'active'}
          forcePage={initialPage}
          disabledClassName={'disabled-page-button'}
        />
      )}
    </div>
  )
}

TablePaginationFooter.propTypes = {
  total: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  currentOffset: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  classnames: PropTypes.string,
}

export default TablePaginationFooter
