import React from 'react'
import PropTypes from 'prop-types'
import {withTranslation} from 'react-i18next'
import {Badge} from 'reactstrap'

import {USER_STATUS} from 'constants/user'

const StatusBadge = ({t, status}) => {
  switch (status) {
    case USER_STATUS.Active:
      return <Badge color="success">{t('Active')}</Badge>
    case USER_STATUS.Blocked:
      return <Badge color="warning">{t('Blocked')}</Badge>
    case USER_STATUS.All:
      return <Badge color="primary">{t('All')}</Badge>

    default:
      return <Badge>{status}</Badge>
  }
}

StatusBadge.propTypes = {
  status: PropTypes.oneOf([USER_STATUS.All, USER_STATUS.Active, USER_STATUS.Blocked]),
  t: PropTypes.func,
}

export default withTranslation()(StatusBadge)
