import {useCallback, useState} from 'react'

import getApiError from 'helpers/getApiError'
import SubscriptionService from '../SubscriptionService'

export const useGiveFreeSubscription = () => {
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const giveFreeSubscription = useCallback(async (values, callback) => {
    setLoading(true)
    return SubscriptionService.giveFreeSubscription(values)
      .then(() => callback())
      .catch((error) => setError(getApiError(error)))
      .finally(() => setLoading(false))
  }, [])

  return {giveFreeSubscription, error, loading}
}
