import React from 'react'
import {Navigate} from 'react-router-dom'
import {SESSION, USER} from 'constants/storageConstants'
import {ADMIN} from 'constants/roles'
import StorageService from 'services/StorageService'
import PropTypes from 'prop-types'

const storageService = new StorageService()

const Authmiddleware = (props) => {
  const isTokenActual = storageService.getValueByKey(SESSION)
  const isUserAdmin = storageService.getValueByKey(USER)?.role === ADMIN

  if (!!isTokenActual && !!isUserAdmin) {
    return <>{props.children}</>
  } else {
    storageService.deleteValueByKey(SESSION)
    storageService.deleteValueByKey(USER)

    return <Navigate to="/login" />
  }
}

Authmiddleware.propTypes = {
  children: PropTypes.node,
}

export default Authmiddleware
