import React from 'react'
import PropTypes from 'prop-types'

const TableNoDataPlaceholder = ({text = 'No Data exists', classnames}) => {
  return (
    <div className={`d-flex align-items-center justify-content-center ${classnames || ''}`}>
      <label className="font-size-16 mt-4 text-secondary ">{text}</label>
    </div>
  )
}

TableNoDataPlaceholder.propTypes = {
  text: PropTypes.string,
  classnames: PropTypes.string,
}

export default TableNoDataPlaceholder
