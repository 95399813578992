import {ALL_OPTION} from './subscriptionManagement'
import {POST_TYPES_LABELS} from '../pages/UserSubscriptionsPage/components/PostsTooltipContent'

// Status badge colors
export const USER_STATUS_BADGE_COLORS = {
  Active: 'success',
  Blocked: 'warning',
}

// Users table pagination
export const USERS_LIMIT = 12

export const USER_MANAGEMENT_FILTERS = [
  {
    label: 'by status',
    name: 'Status',
    initialActive: ALL_OPTION,
    options: [
      {name: 'All', value: ALL_OPTION},
      {name: 'Active', value: 'Active'},
      {name: 'Blocked', value: 'Blocked'},
    ],
  },
  {
    label: 'by subscription',
    name: 'Subscription',
    initialActive: ALL_OPTION,
    options: [
      {name: 'All', value: ALL_OPTION},
      {name: 'Purchased', value: 'Purchased'},
      {name: 'Not purchased', value: 'NotPurchased'},
    ],
  },
  {
    label: 'Subscription Plan',
    name: 'SubscriptionPlan',
    initialActive: ALL_OPTION,
    options: [
      {name: 'All', value: ALL_OPTION},
      {name: 'Month', value: 'Monthly'},
      {name: 'Yearly', value: 'Annual'},
      {name: 'Life-time', value: 'Lifetime'},
    ],
  },
]

export const USER_POSTS_FILTERS = [
  {
    label: "BY POST'S TYPE",
    name: 'PostTypeFilter',
    initialActive: ALL_OPTION,
    options: [
      {name: 'All', value: ALL_OPTION},
      {name: POST_TYPES_LABELS.Image, value: 'Image'},
      {name: POST_TYPES_LABELS.Video, value: 'Video'},
      {name: POST_TYPES_LABELS.Sound, value: 'Sound'},
      {name: POST_TYPES_LABELS.Text, value: 'Text'},
    ],
  },
]

export const BLOCK = 'block'
export const UNBLOCK = 'unblock'

export const USER_PLAN_LABELS = {
  Monthly: 'Month',
  Annual: 'Yearly',
  Lifetime: 'Life-time',
}

export const USER_PLAN_COLORS = {
  Monthly: 'primary',
  Annual: 'purple-shade',
  Lifetime: 'soft-red',
}
